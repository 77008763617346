import axios from "axios";
const statistics = {
  namespaced: true,
  state: () => ({
    statistics: [],
    chart_pie: {},
    chart_12_months: {},
    loading: false,
  }),
  mutations: {
    get_Statistics(state, statistics) {
      let data = [
        {
          title: "الطلاب",
          statistics: statistics.users,
          color: "#624fc6",
          icon: "mdi:users-add",
        },
        {
          title: "الاساتذه",
          statistics: statistics.teachers,
          color: "#FFC94A",
          icon: "fa6-solid:users-rectangle",
        },

        {
          title: "الكورسات",
          statistics: statistics.course,
          color: "#FF9800",
          icon: "material-symbols:play-lesson",
        },
        {
          title: "فئات الكورسات",
          statistics: statistics.course_category,
          color: "#8D493A",
          icon: "material-symbols:list-alt",
        },
        {
          title: "اكواد الشراء",
          statistics: statistics.purchase_code,
          color: "#405D72",
          icon: "icon-park-outline:source-code",
        },
        {
          title: "الدروس",
          statistics: statistics.lessons,
          color: "#D2649A",
          icon: "streamline:class-lesson-solid",
        },
        {
          title: "المشتركين",
          statistics: statistics.enrollments,
          color: "#1F8A70",
          icon: "solar:chat-round-money-bold",
        },
      ];
      
      // Line
      let object = {};
      let datasets = [];
      let chartData = {};
      (object["label"] = "الطلبات المكتملة"),
        (object["backgroundColor"] = "rgba(98,79,198, 0.5)"),
        (object["borderColor"] = "#624fc6"),
        (object["pointRadius"] = 7),
        (object["pointHoverRadius"] = 13),
        (object["pointStyle"] = "circle"),
        (object["data"] = statistics.resulte_sixe_months[0]),
        datasets.push(object);
      chartData["datasets"] = datasets;
      chartData["labels"] = statistics.resulte_sixe_months[1];

      // Pie
      let object2 = {};
      let datasets2 = [];
      let chartData2 = {};
      (chartData2["labels"] = ["دفع مباشر", "زين كاش"]),
        (object2["backgroundColor"] = ["#46C2CB", "#E384FF"]);
      object2["data"] = statistics.enrollments_type;
      datasets2.push(object2);
      chartData2["datasets"] = datasets2;

      state.statistics = data;
      state.chart_12_months = chartData;
      state.chart_pie = chartData2;
    },
  },
  actions: {
    get_statistics({ commit, state, rootState }) {
      state.loading = true;

      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/get_statistics",
          method: "get",
        })
          .then((response) => {
            commit("get_Statistics", response.data.result);
            state.loading = false;

            resolve(response);
          })
          .catch(() => {
            state.loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_lessons_after_refresh({ commit }) {
      commit("GET_LESSONS_AFTER_REFRESH");
    },
  },
};
export default statistics;
