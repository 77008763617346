<template>
  <v-container class="courses">
    <v-toolbar class="mb-5 toolbar" :class="$vuetify.breakpoint.smAndDown ? 'mt-13' : ''">
      <v-row>
        <v-col cols="6" sm="6" md="2" lg="2">
          <v-pagination
            v-model="params.page"
            :length="pageCount"
            circle
            color="#624fc6"
          ></v-pagination>
        </v-col>

        <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
          <v-select
            v-model="params.itemsPerPage"
            :items="items"
            outlined
            rounded
            color="#624fc6"
            single-line
            hide-details
          ></v-select>
        </v-col>
        <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
          <Selects
            @update-value="filter_name_paid_courses = $event"
            :value="filter_name_paid_courses"
            label="الدورات"
            itemValue="value"
            itemText="type"
            :items="types_courses"
          />
        </v-col>

        <v-col align-self="center" cols="6" sm="6" md="5" lg="5">
          <!-- <v-spacer></v-spacer> -->
          <v-text-field
            v-model="paid_courses_category_query"
            @input="queryChange"
            color="#624fc6"
            class="font-weight-black"
            clearable
            append-icon="mdi-magnify"
            outlined
            rounded
            single-line
            hide-details
            label="بحث ..."
          />
        </v-col>
      </v-row>
    </v-toolbar>
    <!-- Loding -->
    <SkeletonLoader :loading="loading" :class="$vuetify.breakpoint.smAndDown ? 'mt-13' : ''"/>

    <!---------------------------------->
    <!-- Card Courses -->
    <Card :courses="paid_courses" :loading="loading" :class="$vuetify.breakpoint.smAndDown ? 'mt-13' : ''"/>
  </v-container>
</template>
<script>
import Card from "@/components/courses/Card.vue";
import Selects from "@/components/layout/Selects.vue";
import SkeletonLoader from "@/components/layout/SkeletonLoader.vue";

export default {
  components: { Selects, Card, SkeletonLoader },
  data() {
    return {
      items: [5, 10, 15, 25, 50, 100],
    };
  },
  created() {
    this.$store.dispatch("courses_category/get_paid_courses");
    this.$store.dispatch("courses/get_courses");
  },
  computed: {
    types_courses() {
      return this.$store.state.courses.types_courses;
    },
    paid_courses() {
      return this.$store.state.courses_category.paid_courses;
    },
    loading() {
      return this.$store.state.courses_category.loading_paid_courses;
    },
    params: {
      set(val) {
        this.$store.state.courses_category.params_paid_courses = val;
      },
      get() {
        return this.$store.state.courses_category.params_paid_courses;
      },
    },
    filter_name_paid_courses: {
      set(val) {
        this.$store.state.courses_category.filter_name_paid_courses = val;
      },
      get() {
        return this.$store.state.courses_category.filter_name_paid_courses;
      },
    },
    pageCount() {
      return this.$store.state.courses_category.pageCount2;
    },
    paid_courses_category_query: {
      set(val) {
        this.$store.state.courses_category.paid_courses_category_query = val;
      },
      get() {
        return this.$store.state.courses_category.paid_courses_category_query;
      },
    },
  },
  methods: {
    get_paid_courses() {
      this.$store.dispatch("courses_category/get_paid_courses");
    },
    queryChange() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.params.page = 1;
        this.get_paid_courses();
      }, 500);
    },
  },
  watch: {
    params: {
      handler() {
        this.get_paid_courses();
      },
      deep: true,
    },
    filter_name_paid_courses: {
      handler() {
        this.get_paid_courses();
      },
    },
  },
};
</script>
<style scoped>
.courses {
  margin-top: 60px;
  margin-bottom: 60px;
}
.link-view-all {
  cursor: pointer;
}
.link-view-all:hover {
  text-decoration: underline;
}
.toolbar {
  background-color: #e0e4e4 !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 0px !important;
  margin-top: -3px;
}
</style>
