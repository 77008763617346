var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading == false)?_c('v-row',{staticClass:"mt-7 d-flex justify-start"},[_vm._l((_vm.courses),function(course,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"card pb-6",attrs:{"to":{
        name: 'courses',
        params: { slug: course.title },
        query: { id: course.id },
      }}},[_c('v-card-title',{staticClass:"pb-1 d-flex justify-center"},[(course.image != null)?_c('v-img',{staticClass:"image",attrs:{"lazy-src":require("@/assets/40-10x6.jpg"),"src":_vm.server + course.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"color":"#ffffff","indeterminate":""}})],1)]},proxy:true}],null,true)}):_vm._e(),(
            course.offer != null &&
            _vm.moment().format('YYYY-MM-DD') <= course.offer_expired
          )?_c('div',{staticClass:"ribbon2 ribbon-top-left"},[_c('span',[_vm._v(_vm._s(course.offer)+"%")])]):_vm._e()],1),_c('v-card-title',{staticClass:"pt-0 d-flex justify-start"},[_c('h5',[_vm._v(_vm._s(course.title))])]),_c('v-card-text',[_c('h4',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("str_limit")(course.description,115))+" ")])]),_c('v-card-actions',[_c('v-avatar',{attrs:{"size":"50","color":"#455A64"}},[(course.user.image != null)?_c('v-img',{attrs:{"src":_vm.server + course.user.image}}):_c('v-icon',{attrs:{"size":"30","color":"white"}},[_vm._v("mdi-account")])],1),_c('h4',{staticClass:"mr-2"},[_vm._v(_vm._s(course.user.name))]),_c('v-spacer'),(course.course_type == 0)?_c('h4',[_vm._v("مجاني")]):(
            course.course_type == 1 &&
            course.offer != null &&
            _vm.moment().format('YYYY-MM-DD') <= course.offer_expired
          )?_c('h4',[_vm._v(" "+_vm._s(_vm._f("formatNumber")((course.price - (course.price / 100) * course.offer)))+" ")]):_c('h4',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(course.price))+" ")]),_c('font-awesome',{staticClass:"mr-2",attrs:{"icon":['fas', 'sack-dollar'],"color":"#388E3C","size":"xl"}})],1),_c('h4',{staticClass:"d-flex justify-end ml-2 mt-n2"},[_vm._v(" "+_vm._s(course.time_course)+" "+_vm._s(course.time_type)+" "),_c('font-awesome',{staticClass:"mr-2",attrs:{"icon":['fas', 'clock'],"color":"#FFA000","size":"xl"}})],1)],1)],1)}),_c('EmptyBox',{attrs:{"loading":_vm.loading,"variable_array":_vm.courses,"Text":"لايوجد دورات متاحه"}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }