import axios from "axios";
const purchase_code = {
  namespaced: true,
  state: () => ({
    purchase_code: [],
    paid_courses: [],

    table_code_loading: false,
    add_code_loading: false,
    pageCount2: 1,
    pageCount: 1,
    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

    params_paid_courses: {
      dropdown: true,
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    courses_state: "done",
  }),
  mutations: {
    ADD_PURCHASE_CODE(state, code) {
      state.purchase_code.unshift(code);
    },

    GET_PURCHASE_CODE(state, code) {
      state.purchase_code.splice(0, state.purchase_code.length);
      code.forEach((element) => {
        state.purchase_code.push(element);
      });
    },

    GET_PAID_COURSES(state, courses) {
      state.paid_courses.splice(0, state.paid_courses.length);
      courses.forEach((element) => {
        state.paid_courses.push(element);
      });
      state.courses_state = "done";
    },

    PAID_COURSES_DREPDWON_SUCCESS(state, courses) {
      if (courses.length == 0) {
        state.courses_state = "finished";
        if (state.params_paid_courses.page > 1)
          state.params_paid_courses.page = state.params_paid_courses.page - 1;
        return;
      }
      state.params_paid_courses.page = state.params_paid_courses.page + 1;
      courses.forEach((element) => {
        state.paid_courses.push(element);
      });
      state.courses_state = "done";
    },
  },
  actions: {
    async reset_table({ state }) {
      state.pageCount = 1;
      state.purchase_code = [];
      state.table_banners_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_purchase_code({ commit, state, rootState }) {
      state.table_code_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        axios({
          url:
            `${rootState.server}` +
            "/api/get_purchase_code" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_PURCHASE_CODE", response.data.result);
            state.table_code_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.table_code_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_purchase_code({ commit, state, rootState }, data) {
      state.table_code_loading = true;
      state.add_code_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/add_purchase_code",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_PURCHASE_CODE", response.data.result[0]);
            state.add_code_loading = false;
            state.table_code_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_code_loading = false;
            state.table_code_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_courses_category_dashboard({ commit, state, rootState }) {
      let data = state.params_paid_courses;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        // فلتر لاحضار الكورسات المفدوعه فقط
        var filter_paid_course = "";
        let filter_paid_courses = {};
        filter_paid_courses["name"] = "course_type";
        filter_paid_courses["value"] = 1;

        if (filter_paid_courses != undefined && filter_paid_courses != null) {
          filter_paid_course =
            "&filter_paid_course=" + JSON.stringify(filter_paid_courses);
        }
        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses_category_dashboard" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            filter_paid_course,
          method: "get",
        })
          .then((response) => {
            state.pageCount2 = response.data.count;
            if (data.dropdown == false) {
              commit("GET_PAID_COURSES", response.data.result);
            } else {
              commit("PAID_COURSES_DREPDWON_SUCCESS", response.data.result);
            }
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default purchase_code;
