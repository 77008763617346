import axios from "axios";

const enrollments = {
  namespaced: true,
  state: () => ({
    enrollments: [],
    paid_courses: [],
    lessons_enrollment: 0,
    enrollments_query: "",
    pageCount: 1,
    pageCount2: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    params_paid_courses: {
      dropdown: true,
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    courses_state: "done",

    loading: false,
    table_enrollments_loading: false,
    buy_course_loading: false,
  }),
  mutations: {
    CHECK_ENROLLMENTS(state, enrollments) {
      //في حال كان الكورس مدفوع او مجاني يجب ان نتاكد  ... يتبع
      if (enrollments.length > 0) {
        state.lessons_enrollment = 1;
      }
      // في حال كان الكورس مجاني
      else if (enrollments[0] == "free") {
        state.lessons_enrollment = 1;
      } else {
        state.lessons_enrollment = 0;
      }
    },

    GET_ENROLLMENTS(state, enrollments) {
      state.enrollments.splice(0, state.enrollments.length);
      enrollments.forEach((element) => {
        state.enrollments.push(element);
      });
    },
    GET_PAID_COURSES(state, courses) {
      state.paid_courses.splice(0, state.paid_courses.length);
      courses.forEach((element) => {
        state.paid_courses.push(element);
      });
      state.courses_state = "done";
    },

    PAID_COURSES_DREPDWON_SUCCESS(state, courses) {
      if (courses.length == 0) {
        state.courses_state = "finished";
        if (state.params_paid_courses.page > 1)
          state.params_paid_courses.page = state.params_paid_courses.page - 1;
        return;
      }
      state.params_paid_courses.page = state.params_paid_courses.page + 1;
      courses.forEach((element) => {
        state.paid_courses.push(element);
      });
      state.courses_state = "done";
    },
  },
  actions: {
    async reset_table({ state }) {
      state.pageCount = 1;
      state.enrollments = [];
      state.table_enrollments_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_enrollments({ commit, state, rootState }) {
      state.table_enrollments_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.enrollments_query != undefined &&
          state.enrollments_query != null &&
          state.enrollments_query.length > 0
        )
          query = `&query=${state.enrollments_query}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_enrollments" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_ENROLLMENTS", response.data.result);
            state.table_enrollments_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.table_enrollments_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    check_enrollments({ commit, state, rootState }, data) {
      state.loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/check_enrollments_lessons",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CHECK_ENROLLMENTS", response.data.result);
            state.loading = false;
            resolve(response);
          })
          .catch(() => {
            state.loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    buy_course_code({ commit, state, rootState }, data) {
      state.buy_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/buy_course_code",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.buy_course_loading = false;

            resolve(response);
          })
          .catch((error) => {
            state.buy_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    buy_course({ commit, state, rootState }, data) {
      state.buy_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/payment",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.buy_course_loading = false;
            const paymentUrl = response.data.result[0]
            window.location.href = paymentUrl;
            resolve(response);
          })
          .catch((error) => {
            state.buy_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#F57C00";
            snack_message["icon"] = "mdi:information";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_paid_courses({ commit, state, rootState }) {
      let data = state.params_paid_courses;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        // فلتر لاحضار الكورسات المفدوعه فقط
        var filter_paid_course = "";
        let filter_paid_courses = {};
        filter_paid_courses["name"] = "course_type";
        filter_paid_courses["value"] = 1;

        if (filter_paid_courses != undefined && filter_paid_courses != null) {
          filter_paid_course =
            "&filter_paid_course=" + JSON.stringify(filter_paid_courses);
        }
        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses_category" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            filter_paid_course,
          method: "get",
        })
          .then((response) => {
            state.pageCount2 = response.data.count;
            if (data.dropdown == false) {
              commit("GET_PAID_COURSES", response.data.result);
            } else {
              commit("PAID_COURSES_DREPDWON_SUCCESS", response.data.result);
            }
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default enrollments;
