import axios from "axios";
const courses = {
  namespaced: true,
  state: () => ({
    courses: [],
    types_courses: [],
    pageCount: 1,

    params_courses: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 12,
    },
    
    courses_query: "",

    add_courses_loading: false,
    pop_delete_loading: false,
    pop_edit_loading: false,
    table_courses_loading: false,
  }),
  mutations: {
    ADD_COURSES(state, course) {
      state.courses.unshift(course);
    },

    EDIT_COURSES(state, courses) {
      let index = state.courses.findIndex((e) => e.id === courses.id);
      state.courses.splice(index, 1);
      state.courses.unshift(courses);
    },

    DELETE_COURSES(state, courses) {
      let index = state.courses.findIndex((e) => e.id == courses.id);
      state.courses.splice(index, 1);
    },

    GET_COURSES(state, course) {
      state.courses.splice(0, state.courses.length);
      course.forEach((element) => {
        let data = {};
        data["name"] = "course_id";
        data["type"] = element.title;
        data["value"] = element.id;
        state.courses.push(element);
        state.types_courses.push(data);
      });
    },
  },
  actions: {
    async reset_table({ state }) {
      state.pageCount = 1;
      state.courses = [];
      state.table_courses_loading = false;
      state.params_courses = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_courses({ commit, state, rootState }) {
      state.table_courses_loading = true;
      let data = state.params_courses;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.courses_query != undefined &&
          state.courses_query != null &&
          state.courses_query.length > 0
        )
          query = `&query=${state.courses_query}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_COURSES", response.data.result);
            state.table_courses_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.table_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_courses({ commit, state, rootState }, data) {
      state.table_courses_loading = true;
      state.add_courses_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/add_courses",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_COURSES", response.data.result[0]);
            state.add_courses_loading = false;
            state.table_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_courses_loading = false;
            state.table_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    edit_courses({ commit, state, rootState }, data) {
      state.pop_edit_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/edit_courses",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "put",
        })
          .then((response) => {
            state.pop_edit_loading = false;
            commit("EDIT_COURSES", response.data.result[0]);
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((err) => {
            state.pop_edit_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = err.response.data.message;
            this.commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              this.commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    delete_courses({ commit, state, rootState }, data) {
      state.pop_delete_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/delete_courses",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_COURSES", data);
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default courses;
