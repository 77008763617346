<template>
  <v-container fluid class="home">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="mt-3">
        <v-card class="card-list mt-10" v-if="loading_banners === true">
          <v-list
            two-line
            style="height: 450px; max-height: 450px"
            class="overflow-y-auto"
          >
            <v-list-item class="d-flex justify-center">
              <v-skeleton-loader width="2100" type="image"></v-skeleton-loader>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-skeleton-loader width="2100" type="image"></v-skeleton-loader>
            </v-list-item>
          </v-list>
        </v-card>
        <v-carousel
          v-if="loading_banners == false && banners.length > 0"
          v-model="model"
          class="carousel"
          height="400"
          hide-delimiter-background
          cycle
        >
          <v-carousel-item
            v-for="(banner, i) in banners"
            :key="i"
            :src="server + banner.image"
            class="link"
            @click="redirects(banner.value)"
          >
          </v-carousel-item>
        </v-carousel>

        <v-carousel
          v-if="loading_banners == false && banners.length == 0"
          v-model="model"
          class="carousel"
          height="400"
          hide-delimiter-background
          cycle
        >
          <v-carousel-item src="../assets/banners.jpg"> </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row v-if="loading == true" class="d-flex justify-start">
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
        v-for="(n, index) in 18"
        :key="'A' + index"
        class="skeleton"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="350px"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row
      class=""
      :class="
        $vuetify.breakpoint.smAndDown
          ? 'd-flex justify-center'
          : 'd-flex justify-start'
      "
      v-if="loading == false"
    >
      <!-- Courses -->
      <v-col
        cols="9"
        sm="6"
        md="3"
        lg="2"
        v-for="(course, index) in courses"
        :key="index"
        class="mt-14 d-flex justify-center"
        @click="redirect_courses(course)"
      >
        <v-card class="card pb-3">
          <v-card-title class="pb-1">
            <v-img
              lazy-src="../assets/40-10x6.jpg"
              :src="server + course.image"
              class="mx-auto image"
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="#ffffff"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-title>
          <v-card-title class="pt-2 d-flex justify-center"
            ><h3 class="text-center">{{ course.title }}</h3>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- end -->
    </v-row>
  </v-container>
</template>

<script>
// import Home from "../components/home/Carousel";
export default {
  // components:{Home},
  name: "Home",
  data() {
    return {
      model: 0,
    };
  },
  created() {
    this.$store.dispatch("banners/get_banners");
  },
  mounted() {
    this.$store.dispatch("courses/get_courses");
  },
  computed: {
    courses() {
      return this.$store.state.courses.courses;
    },
    loading() {
      return this.$store.state.courses.table_courses_loading;
    },
    loading_banners() {
      return this.$store.state.banners.table_banners_loading;
    },
    banners() {
      return this.$store.state.banners.banners;
    },
    server() {
      return this.$store.state.server;
    },
  },
  methods: {
    redirect_courses(course) {
      let data = {};
      data["name"] = "course_id";
      data["type"] = course.title;
      data["value"] = course.id;
      this.$store.state.courses_category.filter_name_courses = data;
      this.$router.push("/available-courses");
    },
    redirects(link) {
      if (link != null && link != "link") {
        window.location.href = link;
      }
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image {
  width: 100%;
  max-width: 200px !important;
  max-height: 150px !important;
  border-radius: 15px 15px 15px 15px !important;
  margin-top: -60px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.image:hover {
  transform: scale(1.1);
  transition: all 0.3s ease 0s;
  box-shadow: rgba(109, 179, 255, 0.3) 0px 15px 30px;
}
.card {
  box-shadow: -4px 10px 50px 9px rgba(0, 0, 0, 0.25) !important;
  border-radius: 15px 15px 15px 15px !important;
  width: 100%;
  text-align: center;
}
.carousel {
  margin-top: 50px;
  border-radius: 10px;
}
.home {
  margin-bottom: 120px;
}
.link {
  cursor: pointer;
}
</style>
