<template>
  <v-app class="app">
    <Header v-if="!$route.meta.hideNavbar" />
    <v-main style="background-color: #e0e4e4" >
      <router-view />
      <SnackBar v-if="snackBar" :snack_message="snack_message" />
    
    </v-main>
    
    <Footer v-if="!$route.meta.hideNavbar"/>  
  </v-app>
  
</template>

<script>
import SnackBar from "./components/layout/Snackbar";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
export default {
  components: { SnackBar, Header, Footer },
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    loggedIn() {
      return this.$store.state.name;
    },
    snackBar() {
      return this.$store.getters.snack_bar;
    },
    snack_message() {
      return this.$store.state.snack_message;
    },
  },
};
</script>
<style>
.app {
  font-family: "Cairo", sans-serif;
}
.v-dialog {
  border-radius: 25px !important;
}
.scroll {
  margin-left: 2px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #ad519c 0%, #624fc6 99%);
  border: solid 1px #e6e6e6;
  border-radius: 100px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss">
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #624fc6 !important;
  color: #ffffff !important;
  font-family: "Cairo", sans-serif !important;
}
// .theme--light.v-data-table > .v-data-table__wrapper {
//   border-radius: 14px !important;
// }
</style>
