<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card class="mx-auto card" width="100%">
        <v-toolbar dark color="#624fc6" class="d-flex justify-center">
          <v-toolbar-title align-center>تعديل معلومات الكورس </v-toolbar-title>
        </v-toolbar>
        <v-form ref="edit_courses">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <Input
                  @update-value="category_info.title = $event"
                  :value="category_info.title"
                  type="text"
                  label="الاسم"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <Selects
                  @update-value="course_type = $event"
                  :value="course_type"
                  itemValue="value"
                  itemText="type"
                  label="نوع فئة الكورس"
                  :items="course_ty"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                v-if="course_type != null && course_type.value == 1"
              >
                <Input
                  @update-value="category_info.price = $event"
                  :value="category_info.price"
                  type="number"
                  label="السعر"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                v-if="course_type != null && course_type.value == 1"
              >
                <Input
                  @update-value="category_info.offer = $event"
                  :value="category_info.offer"
                  type="number"
                  label="قيمة الخصم"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                v-if="course_type != null && course_type.value == 1"
              >
                <InputDate
                  @update-value="category_info.offer_expired = $event"
                  :value="category_info.offer_expired"
                  color="#624fc6"
                  label="تاريخ انتهاء الخصم"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                <Input
                  @update-value="category_info.time_course = $event"
                  :value="category_info.time_course"
                  type="number"
                  label="مدة الكورس"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <Selects
                  @update-value="time_type = $event"
                  :value="time_type"
                  itemValue="value"
                  itemText="type"
                  label="نوع الوقت"
                  :items="time_ty"
                  :rules="RulseAll"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <Textarea
                  @update-value="category_info.description = $event"
                  :value="category_info.description"
                  type="text"
                  label="الوصف"
                  :rules="descriptionRules"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                <v-autocomplete
                  ref="courseCategoryDropdown"
                  :items="course_dashboard"
                  v-model="course_id"
                  color="#624fc6"
                  hide-details="auto"
                  item-text="title"
                  item-value="id"
                  label="الكورسات"
                  :menu-props="menu_props"
                  outlined
                  rounded
                  clearable
                  lazy-rules
                  :rules="RulseAll"
                  class="font-weight-black"
                >
                  <template v-slot:append-item>
                    <div
                      v-intersect="userInteract"
                      v-if="
                        !(
                          $store.state.courses_category.course_state ==
                          'finished'
                        )
                      "
                      class="text-lg-center"
                    ></div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div
                  class="d-flex justify-start mr-2"
                  style="display: flex; justify-content: center"
                >
                  <VueUploadMultipleImage
                    :idUpload="
                      'image-upload-courses-category' + category_info.id
                    "
                    :idEdit="'image-edit-courses-category' + category_info.id"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="images"
                    :multiple="false"
                    dragText="اضف صور"
                    primaryText="تم الاضافة بنجاح"
                    browseText=""
                    maxImageSize="1"
                  />
                </div>
                <h3 class="text-start mt-2 mr-2">تعديل صوره فئة كورس :</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              @click="edit_courses"
              :loading="loading"
              color="#624fc6"
              class="px-4 ml-3"
              elevation="4"
            >
              <h4 style="color: white; font-size: 17px">تحديث</h4>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
              <h4>اغلاق</h4>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Textarea from "@/components/layout/Textarea.vue";
import Selects from "@/components/layout/Selects.vue";
import InputDate from "@/components/layout/InputDate.vue";

export default {
  components: { Input, Selects, Textarea, InputDate },
  data() {
    return {
      course_type: null,
      time_type: null,
      course_id: null,

      course_ty: [
        { type: "مدفوع", value: true },
        { type: " مجاني", value: false },
      ],
      time_ty: [
        { type: "ساعه", value: true },
        { type: " دقيقه", value: false },
      ],

      upload: [],
      images: [],
      RulseAll: [(v) => !!v || "هذا الحقل مطلوب"],
      descriptionRules: [
        (v) =>
          (v || "").length <= 510 ||
          "لقد وصلت الحد الاقصى لعدد الحروف المطلوبة",
      ],
      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },

      category_info: {},
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.state.courses_category.pop_edit_loading;
    },
    course_dashboard() {
      return this.$store.state.courses_category.course_dashboard;
    },
  },
  methods: {
    edit_courses() {
      if (this.$refs.edit_courses.validate()) {
        let data = {};
        data["id"] = this.category_info.id;
        data["title"] = this.category_info.title;
        data["description"] = this.category_info.description;
        data["course_type"] = this.course_type.value;
        data["time_course"] = this.category_info.time_course;
        data["course_id"] = this.course_id;
        data["time_type"] = this.time_type.type;
        if (this.upload.length > 0) {
          data["image"] = this.upload[0];
        }
        if (this.course_type.value == 1) {
          data["price"] = this.category_info.price;
        }
        if (this.category_info.offer != null && this.category_info.offer != 0) {
          data["offer_expired"] = this.category_info.offer_expired;
          data["offer"] = this.category_info.offer;
        }
        this.$store
          .dispatch("courses_category/edit_courses_category", data)
          .then(() => {
            this.$emit("popClose");
            this.images = [];
            this.upload = [];
          });
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    beforeRemove(index, done) {
      var r = confirm("سوف يتم حذف الصورة");
      if (r == true) {
        done();
        this.upload.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    get_course_categories() {
      if (this.$store.state.courses_category.course_state == "finished") return;
      this.$store.dispatch("courses_category/get_courses_dashboard");
    },
    userInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.get_course_categories(); // onscroll
          this.$refs.courseCategoryDropdown.onScroll();
        }, 500);
      }
    },
  },
  watch: {
    category() {
      this.category_info = this.category;
      this.course_id = this.category_info.course_id;

      if (this.category_info.course_type == 1) {
        this.course_type = { type: "مدفوع", value: true };
      } else {
        this.course_type = { type: " مجاني", value: false };
      }
      if (this.category_info.time_type == "ساعه") {
        this.time_type = { type: "ساعه", value: true };
      } else {
        this.time_type = { type: " دقيقه", value: false };
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
