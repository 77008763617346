<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="590">
      <v-card class="card">
        <v-toolbar dark class="d-flex justify-center" color="#455A64">
          <v-toolbar-title align-center>الاشتراكات</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mt-4 d-flex d-flex justify-center">
          <h4>
            ليس لديك اشتراك للاطلاع على اسعار الاشتراكات يرجى الضغط على الزر
            الاشتراك
          </h4>
        </v-card-title>
        <v-card-actions class="d-flex justify-start pb-5 mr-3">
          <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
            <h4>اغلاق</h4>
          </v-btn>

          <v-btn color="#624fc6" class="px-4" elevation="4">
            <h4 style="color: white; font-size: 17px" @click="redirect()">
              الاشتراك
            </h4>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon color="white">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
   
  },
  methods: {
    redirect() {
      this.$router.push("/enrollment");
      this.$emit("popClose");
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
