<template>
  <v-dialog :value="value" scrollable persistent max-width="700px" dir="rtl">
    <v-card class="pb-6">
      <v-card-title>
        <v-tabs
          v-model="tab"
          class="d-flex justify-center"
          slider-color="white"
          show-arrows
        >
          <v-tabs-slider color="#624fc6"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.index"
            ><h4 style="color: black" @click="changeTab(tab.index)">
              {{ tab.title }}
            </h4>
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-tabs-items v-model="tab">
        <!-- tab 1 -->
        <v-tab-item class="py-3" v-if="info_user != null">
          <v-card-title class="pb-1">
            <v-avatar size="120" class="mx-auto avatar" color="#624fc6">
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/svg"
                class="hidden"
                ref="file"
                style="display: none"
                @change="onFileChange"
              />

              <v-img
                @click="browse()"
                v-if="info_user.image != null"
                :src="server + info_user.image"
              >
              </v-img>

              <v-icon @click="browse()" v-else size="50" color="white"
                >mdi-account</v-icon
              >
            </v-avatar>
          </v-card-title>
          <v-form ref="update_user">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <Input
                    @update-value="info_user.name = $event"
                    :value="info_user.name"
                    type="text"
                    label="الاسم"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    color="#624fc6"
                    v-model="password"
                    outlined
                    rounded
                    clearable
                    class="font-weight-black"
                    :type="showPassword ? 'text' : 'password'"
                    label="كلمة المرور"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="PasswordsRules"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-2 mr-3">
              <v-btn
                @click="update_user"
                :loading="loading"
                color="#624fc6"
                class="px-4 ml-3"
                elevation="4"
              >
                <h4 style="color: white; font-size: 17px">تحديث</h4>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
                <h4>الغاء</h4>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>
        <!-- end -->
        <!-- tab 2 -->
        <v-tab-item class="py-3">
          <v-card-title class="pb-1">
            <v-form ref="update_password_user">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field
                      color="#624fc6"
                      v-model="password_old"
                      outlined
                      rounded
                      clearable
                      class="font-weight-black"
                      :type="showPassword1 ? 'text' : 'password'"
                      label="كلمة المرور القديمه"
                      :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword1 = !showPassword1"
                      :rules="PasswordsRules"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field
                      color="#624fc6"
                      v-model="password_new"
                      outlined
                      rounded
                      clearable
                      class="font-weight-black"
                      :type="showPassword2 ? 'text' : 'password'"
                      label="كلمة المرور الجديده"
                      :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword2 = !showPassword2"
                      :rules="PasswordsRules2"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field
                      color="#624fc6"
                      v-model="confirm_password"
                      outlined
                      rounded
                      clearable
                      class="font-weight-black"
                      :type="showPassword2 ? 'text' : 'password'"
                      label="تاكيد كلمة المرور"
                      :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword2 = !showPassword2"
                      :rules="ConfirmPasswordRules"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-2 mr-3">
                <v-btn
                  @click="update_password_user"
                  :loading="loading2"
                  color="#624fc6"
                  class="px-4 ml-3"
                  elevation="4"
                >
                  <h4 style="color: white; font-size: 17px">تحديث</h4>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
                  <h4>الغاء</h4>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-title>
        </v-tab-item>
        <!-- end -->
        <!-- tab 3 -->

        <v-tab-item class="py-3" v-if="info_user != null">
          <v-card-title class="pb-1 d-flex justify-center">
            <v-form ref="update_email_user">
              <v-card-text>
                <v-row v-if="check_email == 0">
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <Input
                      @update-value="info_user.email = $event"
                      :value="info_user.email"
                      type="text"
                      label="البريد الالكتروني"
                      hint="سوف يتم ارسال رمز تاكيد على البريدالالكتروني الجديد"
                      :rules="EmailRules"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field
                      color="#624fc6"
                      v-model="password_email"
                      outlined
                      rounded
                      clearable
                      class="font-weight-black"
                      :type="showPassword3 ? 'text' : 'password'"
                      label="كلمة المرور"
                      :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword3 = !showPassword3"
                      :rules="PasswordsRules2"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <!-- <VerifyPhone
                      :info_student="info_student"
                      @request="requestNewCode"
                      @finish="onFinish"
                    /> -->
                    <v-form ref="form">
                      <v-card class="card">
                        <v-card-title class="d-flex justify-space-between">
                          <div class="mr-5">
                            <h3>ادخل رمز التحقق</h3>
                          </div>
                        </v-card-title>
                        <v-card-text>
                          <h3>
                            تحقق من البريد الالكتروني {{ email }} بحثًا عن رسالة
                            تحقق
                          </h3>
                          <div style="max-width: 300px" class="mt-5">
                            <v-otp-input
                              v-model="otp"
                              :length="length"
                              @finish="email_verification_update"
                              type="number"
                              style="color: #624fc6"
                            ></v-otp-input>
                          </div>

                          <div class="mt-5">
                            <p class="font-weight-black">
                              ألم تتلق أي شيء؟
                              <strong
                                @click="requestNewCode"
                                class="ms-1"
                                :class="
                                  timerRunning ? 'text-disabled' : 'text-strong'
                                "
                                >اطلب رمزا جديدا</strong
                              >
                              <strong
                                class="ms-10"
                                style="font-weight: bold"
                                v-if="timerRunning"
                                ><strong
                                  style="font-weight: bold; color: #624fc6"
                                  >{{ timer }}
                                </strong>
                                :الوقت المتبقي
                              </strong>
                            </p>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-2 mr-3" v-if="check_email == 0">
                <v-btn
                  @click="update_email_user"
                  :loading="loading3"
                  color="#624fc6"
                  class="px-4 ml-3"
                  elevation="4"
                >
                  <h4 style="color: white; font-size: 17px">تحديث</h4>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
                  <h4>الغاء</h4>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-title>
        </v-tab-item>
        <!-- end -->
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>
<script>
import Input from "@/components/layout/Input";
export default {
  props: {
    student: {
      type: Object,
      require: true,
    },
    value: Boolean,
  },
  components: { Input },
  data() {
    return {
      otp: "",
      length: 6,
      timer: 0,
      timerRunning: false,
      tabs: [
        { title: "الملف الشخصي", index: 0 },
        { title: "كلمة المرور", index: 1 },
        { title: "البريد الالكتروني", index: 2 },
      ],
      EmailRules: [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 7 || "الحد الادنى لعدد الاحرف هوه 7",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "ادخلت رموز غير صحيحه";
        },
      ],
      tab: 0,
      check_email: 0,
      image: [],
      password: null,
      password_email: null,
      showPassword: false,
      info_user: null,

      password_old: null,
      showPassword1: null,
      password_new: null,
      confirm_password: null,
      showPassword2: null,
      new_phone: null,
      password_phone: null,
      showPassword3: null,
      email: localStorage.getItem("email") ?? null,
    };
  },
  mounted() {
    this.$store.dispatch("users/get_user");
  },
  computed: {
    All_Rules() {
      return [(value) => !!value || "هذا الحقل مطلوب"];
    },
    nameRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
      ];
    },

    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
      ];
    },
    PasswordsRules2() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
      ];
    },
    // EmailRules() {
    //   return [
    //     (value) => !!value || "هذا الحقل مطلوب",
    //     (value) =>
    //       !value ||
    //       /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
    //       "يجب ان يكون البريد الاكتروني صحيح",
    //   ];
    // },
    ConfirmPasswordRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
        (value) =>
          value === (this.password_new || "") || "كلمة المرور غير متطابقه",
      ];
    },

    server() {
      return this.$store.state.server;
    },
    user() {
      return this.$store.state.users.user;
    },
    loading() {
      return this.$store.state.users.U_user_loading;
    },
    loading2() {
      return this.$store.state.users.U_password_loading;
    },
    loading3() {
      return this.$store.state.users.U_email_loading;
    },
  },
  methods: {
    browse() {
      this.$refs.file.click();
    },
    uploadImage(value) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.update_image_user(e.target.result);
      };
      reader.readAsDataURL(value.target.files[0]);
    },
    onFileChange(value) {
      if (value.target.files[0].size > 1024 * 1024) {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "ri:close-circle-fill";
        snack_message["text"] = "حجم الصورة اكبر من 1MB";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
        return;
      } else if (!value) {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "ri:close-circle-fill";
        snack_message["text"] = "لم يتم اضافة اي صورة";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      } else {
        this.uploadImage(value);
      }
    },

    update_image_user(image) {
      let data = {};
      data["id"] = this.info_user.id;
      data["image"] = image;
      this.$store.dispatch("users/update_image_user", data).then((res) => {
        this.info_user.image = res.data.result[0].image;
        this.$emit("popClose");
      });
    },
    update_user() {
      if (this.$refs.update_user.validate()) {
        let data = {};
        data["id"] = this.info_user.id;
        data["name"] = this.info_user.name;
        data["password"] = this.password;
        this.$store.dispatch("users/update_user", data).then(() => {
          this.password = "";
          this.$emit("popClose");
        });
      }
    },
    update_password_user() {
      if (this.$refs.update_password_user.validate()) {
        let data = {};
        data["id"] = this.info_user.id;
        data["password_old"] = this.password_old;
        data["password_new"] = this.password_new;

        this.$store.dispatch("users/update_password_user", data).then(() => {
          this.password = "";
          this.$emit("popClose");
        });
      }
    },
    update_email_user() {
      if (this.$refs.update_email_user.validate()) {
        let data = {};
        data["id"] = this.info_user.id;
        data["password"] = this.password_email;
        data["email"] = this.info_user.email;
        localStorage.setItem("email", this.info_user.email);

        this.$store.dispatch("users/update_email_user", data).then(() => {
          this.check_email = 1;
          this.password_email = "";
        });
      }
    },
    email_verification_update() {
      if (this.$refs.update_email_user.validate()) {
        let data = {};
        data["id"] = this.info_user.id;
        data["random_code"] = this.otp;
        data["email"] = localStorage.getItem("email");

        this.$store
          .dispatch("users/email_verification_update", data)
          .then(() => {
            this.check_email = 0;
            this.$emit("popClose");
          });
      }
    },

    changeTab(index) {
      this.tab = index;
    },
    requestNewCode() {
      let data = {};
      this.startTimer();
      data["email"] = localStorage.getItem("email");
      this.$store.dispatch("users/send_code_again_update", data);
    },

    startTimer() {
      this.timerRunning = true;
      this.timer = 120;

      this.timerInterval = setInterval(() => {
        if (this.timer == 0) {
          this.timerRunning = false;
        }
        this.timer--;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  watch: {
    user() {
      this.info_user = this.user[0];
    },
  },
};
</script>
<style scoped>
.avatar {
  cursor: pointer;
}
.card {
  border-radius: 15px;
  box-shadow: 0px 0px 0px 0px !important;
}

.text-strong {
  cursor: pointer;
  color: #624fc6;
}
.text-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.text-strong:hover {
  text-decoration: underline;
}
</style>
