<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card class="mx-auto card" width="100%">
        <v-toolbar dark color="#624fc6" class="d-flex justify-center">
          <v-toolbar-title align-center>تعديل معلومات الاعلان </v-toolbar-title>
        </v-toolbar>
        <v-form ref="edit_banners">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Input
                  @update-value="banners_info.value = $event"
                  :value="banners_info.value"
                  type="text"
                  label="قيمه"
                  :rules="nameRules"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <div
                  class="d-flex justify-start mr-2"
                  style="display: flex; justify-content: center"
                >
                  <VueUploadMultipleImage
                    :idUpload="'image-upload-banners' + banners_info.id"
                    :idEdit="'image-edit-banners' + banners_info.id"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="images"
                    dragText="اضف صور"
                    primaryText="تم الاضافة بنجاح"
                    browseText=""
                    maxImageSize="5"
                  />
                </div>
                <h3 class="text-start mt-2 mr-2">تعديل صورة الاعلان :</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              @click="edit_banners"
              :loading="loading"
              color="#624fc6"
              class="px-4 ml-3"
              elevation="4"
            >
              <h4 style="color: white; font-size: 17px">تحديث</h4>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
              <h4>اغلاق</h4>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";

export default {
  components: { Input },
  data() {
    return {
      upload: [],
      images: [],
      nameRules: [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
      ],

      banners_info: {},
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    banners: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loading() {
      return this.$store.state.banners.pop_edit_loading;
    },
  },
  methods: {
    edit_banners() {
      if (this.$refs.edit_banners.validate()) {
        let data = {};
        data["id"] = this.banners_info.id;
        data["value"] = this.banners_info.value;

        if (this.upload.length > 0) {
          data["image"] = this.upload[0];
        }
        this.$store.dispatch("banners/edit_banners", data).then(() => {
          this.$emit("popClose");
          this.images = [];
          this.upload = [];
        });
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    beforeRemove(index, done) {
      var r = confirm("سوف يتم حذف الصورة");
      if (r == true) {
        done();
        this.upload.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
  },
  watch: {
    banners() {
      this.banners_info = this.banners;
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
