import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";
import AvailableCourses from "../views/AvailableCourses.vue";
import Lessons from "../views/Lessons.vue";
import PaidCourses from "../views/PaidCourses.vue";
import Login from "../views/Login/Login.vue";
import Register from "../views/Login/Register.vue";
import ConfirmEmail from "../views/Login/ConfirmEmail.vue";
import ForgotPassword from "../views/Login/ForgotPassword.vue";
import Enrollment from "../views/Enrollment.vue";
import Dashboard from "../views/Dashboard.vue";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: loggedIn,
    meta: {
      title: "منصة تعليمي",
    },
  },
  {
    path: "/available-courses",
    name: "AvailableCourses",
    component: AvailableCourses,
    beforeEnter: loggedIn,
    meta: {
      title: "كورسات منصة تعليمي",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      hideNavbar: true,
      title: "لوحة التحكم منصة تعليمي",
    },
    component: Dashboard,
    beforeEnter: (loggedIn, Admin),
  },
  {
    path: "/enrollment",
    name: "Enrollment",
    component: Enrollment,
    beforeEnter: loggedIn,
    meta: {
      title: "اشتراكات الكورسات منصة تعليمي",
    },
  },
  {
    path: "/available-courses/:slug",
    name: "courses",
    props: true,
    component: Lessons,
    beforeEnter: (loggedIn),
    meta: {
      title: "slug",
    },
  },
  {
    path: "/paid-courses",
    name: "PaidCourses",
    component: PaidCourses,
    beforeEnter: loggedIn,
    meta: {
      title: "كورسات المدفوعه منصة تعليمي",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: redirect,
    meta: {
      hideNavbar: true,
      title: "تسجيل الدخول منصة تعليمي",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: redirect,
    meta: {
      hideNavbar: true,
      title: "تسجيل حساب جديد منصة تعليمي",
    },
  },
  {
    path: "/confirm_email",
    name: "confirm_email",
    component: ConfirmEmail,
    beforeEnter: confirm,
    meta: {
      hideNavbar: true,
      title: "  تاكيد البريد الالكتروني منصة تعليمي",
    },
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: ForgotPassword,
    meta: {
      hideNavbar: true,
      title: "اعادة تعين كلمة المرور منصة تعليمي",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "منصة تعليمي";
  next();
});

export default router;
function loggedIn(to, from, next) {
  const token = localStorage.getItem("token");
  if (token) {
    next();
    if (to.params.slug) {
      document.title = to.params.slug
        ? `${to.params.slug}`
        : "دروس منصة تعليمي";
      next();
    }
  } else {
    next("/login");
  }
}
function redirect(to, from, next) {
  const token = localStorage.getItem("token");
  if (token) {
    next("/");
  } else {
    next();
  }
}

function confirm(to, from, next) {
  const confirm = localStorage.getItem("confirm");
  if (confirm == 0) {
    if (to.path === "/confirm_email") {
      next();
    } else {
      next("/confirm_email");
    }
  } else {
    next("/login");
  }
}

// else if(localStorage.getItem("confirm") == 0){
//   next("/confirm_email");
// }
function Admin(to, from, next) {
  const admin = localStorage.getItem("admin");
  if (admin == 0 || admin == 1) {
    next();
  } else {
    next({ name: "home" });
  }
}
// function setTitle(to, from, next) {
//   if (to.params.slug) {
//     document.title = to.params.slug ? `${to.params.slug}` : "دروس منصة تعليمي";
//     next();
//   }
// }
