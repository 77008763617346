<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card">
          <v-toolbar dark color="#624fc6">
            <v-toolbar-title align-center>اضافة كورسات</v-toolbar-title>
          </v-toolbar>
          <v-form ref="add_courses">
            <v-card-text class="mt-7">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <Input
                    @update-value="title = $event"
                    :value="title"
                    type="text"
                    label="الاسم"
                    :rules="nameRules"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <div
                    class="d-flex justify-start mr-2"
                    style="display: flex; justify-content: center"
                  >
                    <VueUploadMultipleImage
                      idUpload="image-upload-courses"
                      idEdit="image-edit-courses"
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      :multiple="false"
                      dragText="اضف صور"
                      primaryText="تم الاضافة بنجاح"
                      browseText=""
                      maxImageSize="1"
                    />
                  </div>
                  <h3 class="text-start mt-2 mr-2">اضف صوره الكورس :</h3>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mt-2 pb-5">
              <v-btn
                @click="add_courses"
                :loading="loading"
                color="#624fc6"
                large
                rounded
                class="px-16 mx-auto"
                elevation="4"
              >
                <h4 style="color: white; font-size: 17px">اضافة</h4>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <Table />
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Table from "@/components/courses/Table.vue";
export default {
  components: { Input, Table },

  data() {
    return {
      title: null,
      images: [],
      upload: [],

      nameRules: [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
      ],
    };
  },

  computed: {
    loading() {
      return this.$store.state.courses.add_courses_loading;
    },
  },
  methods: {
    add_courses() {
      if (this.$refs.add_courses.validate()) {
        let data = {};
        data["title"] = this.title;
        data["image"] = this.upload[0];
        this.$store.dispatch("courses/add_courses", data).then(() => {
          this.$refs.add_courses.reset();
          this.images = [];
          this.upload = [];
        });
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    beforeRemove(index, done) {
      var r = confirm("سوف يتم حذف الصورة");
      if (r == true) {
        done();
        this.upload.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
</style>
