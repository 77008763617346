<template>
  <v-container class="login" fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-form ref="form">
          <v-card class="card" width="1000">
            <v-card-title class="d-flex justify-space-between">
              <div class="mr-5">
                <h3>ادخل رمز التحقق</h3>
              </div>
              <v-sheet
                color="#624fc6"
                elevation="6"
                max-width="90px"
                class="v-sheet-card d-flex justify-center"
              >
                <v-badge
                  offset-y="11"
                  offset-x="15"
                  bordered
                  color="#624fc6"
                  content="1"
                >
                  <v-icon size="35" color="white">mdi-email</v-icon>
                </v-badge>
              </v-sheet>
            </v-card-title>
            <v-card-text>
              <h3>تحقق من البريد الالكتروني {{ email }} بحثًا عن رسالة تحقق</h3>
              <div style="max-width: 300px" class="mt-5">
                <v-otp-input
                  v-model="otp"
                  :length="length"
                  @finish="onFinish"
                  type="number"
                ></v-otp-input>
              </div>

              <div class="mt-5">
                <p class="font-weight-black">
                  ألم تتلق أي شيء؟
                  <strong
                    @click="requestNewCode"
                    class="ms-1"
                    :class="timerRunning ? 'text-disabled' : 'text-strong'"
                    >اطلب رمزا جديدا</strong
                  >
                  <strong
                    class="ms-10"
                    style="font-weight: bold"
                    v-if="timerRunning"
                    ><strong style="font-weight: bold; color: #624fc6"
                      >{{ timer }}
                    </strong>
                    :الوقت المتبقي
                  </strong>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      otp: "",
      length: 6,
      timer: 0,
      timerRunning: false,
    };
  },
  computed: {
    email() {
      return this.$store.state.email;
    },
  },
  methods: {
    requestNewCode() {
      let data = {};
      this.startTimer();
      data["id"] = localStorage.getItem("user_id");
      this.$store.dispatch("sendCodeAgain", data);
    },
    onFinish(res) {
      let data = {};
      data["random_code"] = res;
      data["id"] = localStorage.getItem("user_id");
      this.$store.dispatch("verifyAuthentication", data).then(() => {
        this.$router.push({ name: "login" });
      });
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 120;

      this.timerInterval = setInterval(() => {
        if (this.timer == 0) {
          this.timerRunning = false;
        }
        this.timer--;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>
<style scoped>
.card {
  margin-top: 60px !important;
  margin: auto;
  border-radius: 15px;
  font-family: "Cairo", sans-serif;
  box-shadow: 0px 0px !important;
}
.v-sheet-card {
  margin-top: -40px;
  border-radius: 3px;
  position: relative;
  padding: 30px;
}
.text-strong {
  cursor: pointer;
  color: #624fc6;
}
.text-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
.text-strong:hover {
  text-decoration: underline;
}
</style>
