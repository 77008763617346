<template>
  <div>
    <v-card class="card" width="100%" :height="500">
      <v-card-title class="d-flex justify-center pb-0 mb-0"
        >احصائيات افضليات الدفع
      </v-card-title>
      <Pie
        :width="400"
        :height="353"
        :chart-options="chartOptions"
        :chart-data="chartPie"
      />
    </v-card>
  </div>
</template>
<script>
import { Pie } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  components: { Pie },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 14,
                family: "'Cairo', sans-serif",
                weight: "bold",
              },
            },
          },
        },
      },
    };
  },
  computed: {
    chartPie() {
      return this.$store.state.statistics.chart_pie;
    },
  },
};
</script>
<style scoped>
 .card {
    border-radius: 25px !important;
    box-shadow: 0px 0px 0px 0px !important;
  }
  </style>
