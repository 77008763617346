<template>
  <v-container class="reset_password" fluid>
    <v-row class="justify-center">
      <v-col cols="12" v-if="check == 0">
        <v-form ref="send_email">
          <v-card class="card py-16" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="12" md="7" lg="7" class="justify-center">
                <v-card-title class="d-flex justify-center">
                  <h2 style="color: #624fc6">تعليمي</h2>
                </v-card-title>
                <v-card-title class="d-flex justify-center">
                  <h3 style="color: #6f6f70">اعادة تعين كلمة المرور</h3>
                </v-card-title>
                <v-card-text class="mt-2">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <Input
                          @update-value="email = $event"
                          :value="email"
                          type="text"
                          hint="سوف يتم ارسال رمز تاكيد على ايميل الذي سوف تدخله"
                          label="البريدالبريد الالكتروني"
                          :rules="EmailRules"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-btn
                    @click="send"
                    :loading="loading"
                    color="#624fc6"
                    large
                    rounded
                    class="px-16 mx-auto"
                    elevation="4"
                  >
                    <h4 style="color: white; font-size: 17px">ارسال</h4>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="7"
        lg="5"
        class="mx-auto col-verify"
        v-else-if="check == 1"
      >
        <v-card class="card" width="1000">
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-5">
              <h3>ادخل رمز التحقق</h3>
            </div>
            <v-sheet
              color="#624fc6"
              elevation="6"
              max-width="90px"
              class="v-sheet-card d-flex justify-center"
            >
              <v-badge
                offset-y="11"
                offset-x="15"
                bordered
                color="#624fc6"
                content="1"
              >
                <v-icon size="35" color="white">mdi-email</v-icon>
              </v-badge>
            </v-sheet>
          </v-card-title>
          <v-card-text>
            <h3>تحقق من البريد الالكتروني {{ email }} بحثًا عن رسالة تحقق</h3>
            <div style="max-width: 300px" class="mt-5">
              <v-otp-input
                v-model="otp"
                :length="length"
                @finish="onFinish"
                type="number"
              ></v-otp-input>
            </div>

            <div class="mt-5">
              <p class="font-weight-black">
                ألم تتلق أي شيء؟
                <strong
                  @click="requestNewCode"
                  class="ms-1"
                  :class="timerRunning ? 'text-disabled' : 'text-strong'"
                  >اطلب رمزا جديدا</strong
                >
                <strong
                  class="ms-10"
                  style="font-weight: bold"
                  v-if="timerRunning"
                  ><strong style="font-weight: bold; color: #624fc6"
                    >{{ timer }}
                  </strong>
                  :الوقت المتبقي
                </strong>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="justify-center"
        v-else-if="check == 2"
      >
        <v-form ref="reset_password">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="12" md="7" lg="7" class="justify-center">
                <v-card-title class="d-flex justify-center">
                  <h2 style="color: #624fc6">تعليمي</h2>
                </v-card-title>
                <v-card-title class="d-flex justify-center">
                  <h3 style="color: #6f6f70">اعادة تعين كلمة المرور</h3>
                </v-card-title>

                <v-card-text class="mt-2">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
                        <v-text-field
                          color="#624fc6"
                          v-model="password_new"
                          outlined
                          rounded
                          clearable
                          class="font-weight-black"
                          :type="showPassword ? 'text' : 'password'"
                          label="كلمة المرور الجديده"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          :rules="PasswordsRules"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        class="py-0 my-0"
                      >
                        <v-text-field
                          color="#624fc6"
                          v-model="confirm_password"
                          outlined
                          rounded
                          clearable
                          class="font-weight-black"
                          :type="showPassword ? 'text' : 'password'"
                          label="تاكيد كلمة المرور الجديده"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          :rules="ConfirmPasswordRules"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-btn
                    @click="reset_password"
                    :loading="loading"
                    color="#624fc6"
                    large
                    rounded
                    class="px-16 mx-auto"
                    elevation="4"
                  >
                    <h4 style="color: white; font-size: 17px">تاكيد</h4>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input";
export default {
  components: { Input },
  data() {
    return {
      otp: "",
      length: 6,
      timer: 0,
      timerRunning: false,
      password_new: null,
      confirm_password: null,
      showPassword: false,
      check: 0,
      email: null,
      zipcode: null,

      EmailRules: [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 7 || "الحد الادنى لعدد الاحرف هوه 7",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "ادخلت رموز غير صحيحه";
        },
      ],
    };
  },
  mounted() {
    if (
      localStorage.getItem("id_reset_password") ||
      localStorage.getItem("email")
    ) {
      localStorage.removeItem("email");
      localStorage.removeItem("id_reset_password");
    }
  },
  computed: {
    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
      ];
    },
    ConfirmPasswordRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
        (value) =>
          value === (this.password_new || "") || "كلمة المرور غير متطابقه",
      ];
    },

    loading() {
      return this.$store.state.forgot_password_loading;
    },
  },
  methods: {
    send() {
      if (this.$refs.send_email.validate()) {
        localStorage.setItem("email", this.email);
        let data = {};
        data["email"] = this.email;

        this.$store.dispatch("send_code_forgot_password", data).then(() => {
          this.check = 1;
        });
      }
    },
    reset_password() {
      if (this.$refs.reset_password.validate()) {
        let data = {};
        data["id"] = localStorage.getItem("id_reset_password");
        data["password"] = this.password_new;

        this.$store.dispatch("reset_password", data).then(() => {
          this.$router.push({ name: "login" });
          this.check = 0;
          this.id_reset_password = null;
        });
      }
    },

    requestNewCode() {
      let data = {};
      this.startTimer();
      data["email"] = localStorage.getItem("email");
      this.$store.dispatch("send_code_forgot_password", data);
    },

    onFinish(res) {
      let data = {};
      data["random_code"] = res;
      data["email"] = localStorage.getItem("email");
      this.$store.dispatch("check_code_forgot_password", data).then(() => {
        this.check = 2;
      });
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 120;

      this.timerInterval = setInterval(() => {
        if (this.timer == 0) {
          this.timerRunning = false;
        }
        this.timer--;
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>
<style scoped>
.reset_password {
  height: 100%;
}
.card {
  margin-top: 100px !important;
  margin: auto;
  border-radius: 15px;
  font-family: "Cairo", sans-serif;
  box-shadow: 0px 0px !important;
}
.v-sheet-card {
  margin-top: -40px;
  border-radius: 3px;
  position: relative;
  padding: 30px;
}
.text-strong {
  cursor: pointer;
  color: #624fc6;
}
.text-strong:hover {
  text-decoration: underline;
}
.disabled-strong {
  pointer-events: none;
  font-weight: bold;
  text-decoration: line-through !important;
}
.verify {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px !important;
}
.col-verify {
  padding-top: 80px;
}
</style>
