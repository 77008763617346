<template>
  <div>
    <v-app-bar
      color="white"
      elevate-on-scroll
      absolute
      scroll-target="#scrolling-techniques-7"
    >
      <!-- tabs -->
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        class="d-flex justify-end toolbar-title"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'ps-10'"
        style="color: black"
        @click="link_home"
      >
        <!--  site name -->
        <v-img
          src="../../assets/play_home.png"
          class="ml-5"
          width="30"
          alt="play"
        ></v-img>
        <h4>تعليمي</h4>
      </v-toolbar-title>
      <v-spacer />
      <v-tabs
        :class="
          $vuetify.breakpoint.smAndDown ? 'd-none' : 'd-flex justify-center'
        "
      >
        <v-tabs-slider color="#624fc6"></v-tabs-slider>
        <v-tab v-for="(tab, i) in items" :key="i" :to="tab.link">
          <h4 style="color: black">{{ tab.text }}</h4>
        </v-tab>
      </v-tabs>
      <!-- end -->

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" style="cursor: pointer" class="mr-1">
            <v-chip link>
              <span>{{ name_user }}</span>
              <v-badge dot bottom offset-y="10" offset-x="10" color="green">
                <v-avatar size="40">
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </v-badge>
            </v-chip>
          </span>
        </template>
        <v-list width="250">
          <v-list-item-group mandatory v-model="model">
            <v-list-item
              style="cursor: pointer"
              class="hover-list"
              @click="profile()"
            >
              <v-list-item-icon>
                <font-awesome
                  :icon="['fas', 'user']"
                  color="#624fc6"
                  size="xl"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> <h4>الملف الشخصي</h4></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              v-if="(user_type != null && user_type == 0) || user_type == 1"
              style="cursor: pointer"
              @click="Dashboard()"
              class="hover-list"
            >
              <v-list-item-icon>
                <v-icon color="#624fc6">mdi-monitor-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title><h4>لوحة التحكم</h4> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              style="cursor: pointer"
              @click="logout"
              class="hover-list"
            >
              <v-list-item-icon>
                <font-awesome
                  :icon="['fas', 'fa-right-from-bracket']"
                  color="#624fc6"
                  size="xl"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title><h4>خروج</h4> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <Profile :value="pop_user" v-on:popClose="popClose()" />
      </v-menu>
      <!-- end -->
    </v-app-bar>
    <!-- drawer -->

    <v-navigation-drawer v-model="drawer" right nable-resize-watcher app>
      <v-list nav dense>
        <v-list-item-group :value="true" color="#624fc6">
          <v-list-item v-for="(tab, i) in items" :key="i" :to="tab.link">
            <v-list-item-title class="d-flex justify-center"
              ><h3>
                {{ tab.text }}
              </h3></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- end -->
  </div>
</template>
<script>
import Profile from "@/components/user/Profile";
export default {
  components: { Profile },
  data() {
    return {
      model: null,
      drawer: false,
      pop_user: false,
      items: [
        { text: "الرئيسية", link: "/" },
        { text: "الدورات المتاحه ", link: "/available-courses" },
        { text: "الدورات المدفوعه", link: "/paid-courses" },
        { text: "الاشتراكات", link: "/enrollment" },
      ],
    };
  },
  computed: {
    name_user() {
      return this.$store.state.name;
    },
    user_type() {
      return this.$store.state.users.user_type;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    Dashboard() {
      this.$router.push("/dashboard");
    },
    profile() {
      this.pop_user = !this.pop_user;
    },
    popClose() {
      this.pop_user = false;
    },
    link_home() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },

};
</script>
<style scoped>
.toolbar-title {
  cursor: pointer;
}
</style>
