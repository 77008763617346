<template>
  <v-col
    v-if="variable_array.length == 0 && loading == false"
    class="d-flex justify-center"
    cols="12"
  >
    <v-card class="empty">
      <v-img width="300" height="300" src="@/assets/empty-box.png"> </v-img>
      <v-card-title class="d-flex justify-center">
        <h3>  {{Text}} </h3>
      </v-card-title></v-card
    >
  </v-col>
</template>
<script>
export default {
  props: {
    loading: {
      typeof: Boolean,
      default: false,
    },
    variable_array: {
      typeof: Array,
      require: true,
    },
    Text: {
      typeof: String,
      require: true,
    },
  },
};
</script>
<style scoped>
.empty {
  margin-top: 50px;
  background-color: #e0e4e4 !important;
  box-shadow: 0px 0px !important;
}
</style>