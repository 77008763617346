<template>
  <v-container>
    <!-- Statistics -->
    <v-row v-if="model == 100 && loading == false">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(option, index) in statistics"
        class="mt-2"
        :key="index"
      >
        <Statistics
          :title="option.title"
          :icon="option.icon"
          :statistics="option.statistics"
          :color="option.color"
        />
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="4" class="mx-auto mt-5">
        <Pie />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="8" class="mx-auto mt-5">
        <LineChartGenerator />
      </v-col>
    </v-row>

    <!-- skeleton -->
    <v-row v-if="model == 100 && loading == true">
      <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      class="mt-2"
      v-for="(n, index) in 7"
      :key="'A' + index"
    >
      <v-skeleton-loader type="image" height="110"></v-skeleton-loader>
    </v-col>

    <v-col cols="12" sm="12" md="12" lg="4" class="mx-auto mt-5">
      <v-card class="card" width="100%" :height="500">
        <v-card-title class="d-flex justify-center pb-0 mb-0"
          >احصائيات افضليات الدفع
        </v-card-title>
        <v-skeleton-loader
          class="mt-10 skeleton-Pie mx-auto"
          height="351"
          type="image"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="8" class="mx-auto mt-5">
      <v-card class="card" width="100%" :height="500">
        <v-card-title class="d-flex justify-center pb-0 mb-0"
          >احصائيات الطلاب المشتركين
        </v-card-title>

        <v-skeleton-loader
          class="mt-10 mx-auto"
          height="360"
          :width="width"
          type="image"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    </v-row>
    <!-- end -->

    <!-- Users -->
    <v-row v-else-if="model == 200">
      <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto mt-5">
        <Users />
      </v-col>
    </v-row>
    <!-- end -->

    <!-- Courses -->
    <v-row v-else-if="model == 2">
      <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto">
        <Courses />
      </v-col>
    </v-row>
    <!-- end -->
    <!-- Courses -->
    <v-row v-else-if="model == 3">
      <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto">
        <CoursesCategory />
      </v-col>
    </v-row>
    <!-- end -->

    <!-- Lessons -->
    <v-row v-else-if="model == 4">
      <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto">
        <Lessons />
      </v-col>
    </v-row>
    <!-- end -->

    <!-- Enrollments -->
    <v-row v-else-if="model == 5">
      <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto">
        <Enrollments />
      </v-col>
    </v-row>
    <!-- end -->
    <!-- Banners -->
    <v-row v-else-if="model == 6">
      <PurchaseCode />
    </v-row>
    <!-- end -->
    <!-- Banners -->
    <v-row v-else-if="model == 300">
      <Banners />
    </v-row>
    <!-- end -->
    <SideBar :model="model" @update-model="updateModel" />
  </v-container>
</template>

<script>
import SideBar from "../components/layout/SideBar";
import Statistics from "../components/admin/Statistics";
import LineChartGenerator from "../components/admin/LineChartGenerator";
import Pie from "../components/admin/Pie";
import Users from "../components/admin/Users";
import Courses from "../components/admin/Courses";
import CoursesCategory from "../components/admin/CoursesCategory";
import Banners from "../components/admin/Banners";
import Enrollments from "../components/admin/Enrollments";
import Lessons from "../components/admin/Lessons";
import PurchaseCode from "../components/admin/PurchaseCode";
// import skeletonDashboard from "../components/layout/skeletonDashboard";

export default {
  components: {
    SideBar,
    Statistics,
    LineChartGenerator,
    Pie,
    Users,
    Courses,
    CoursesCategory,
    Banners,
    Enrollments,
    Lessons,
    PurchaseCode,
    // skeletonDashboard,
  },
  data() {
    return {
      // model: localStorage.getItem("model") || this.$store.state.model,
    };
  },
  created() {
    this.$store.dispatch("statistics/get_statistics");
  },

  computed: {
    loading() {
      return this.$store.state.statistics.loading;
    },
    statistics() {
      return this.$store.state.statistics.statistics;
    },
    model: {
      set(val) {
        this.$store.state.model = val;
      },
      get() {
        return this.$store.state.model;
      },
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 250;
        case "sm":
          return 550;
        case "md":
          return 800;
        case "lg":
          return 1000;
        case "xl":
          return 1000;
        default:
          return 1000; // Default value if none of the cases match
      }
    },
  },
  methods: {
    updateModel(value) {
      this.model = value;
    },
    updateDrawer(drawer) {
      this.drawer = drawer;
    },
  },
  watch: {
    model(newValue) {
      localStorage.setItem("model", newValue);
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.skeleton-Pie {
  border-radius: 110px !important;
  width: 250px !important;
}
</style>
<style>
.v-skeleton-loader__image {
  height: 280px !important;
}
</style>
