<template>
    <v-card class="mx-auto mt-8 card-table" width="100%">
      <v-row class="d-flex justify-center mb-9">
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="banners"
            :options.sync="pagination"
            :page.sync="pagination.page"
            :items-per-page="pagination.itemsPerPage"
            :loading="loading || false"
            hide-default-footer
            loading-text="جاري التحميل يرجى الأنتظار"
            height="600"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat class="mt-2">
                <v-toolbar-title>جدول الاعلانات</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="reset_table"
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <Icon
                        icon="solar:smartphone-update-bold"
                        color="#624fc6"
                        width="30"
                      />
                    </v-btn>
                  </template>
                  <span>تحديث معلومات الجدول</span>
                </v-tooltip>
              </v-toolbar>
            </template>
  
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center font-weight-black">
                  {{ item.value }}
                </td>
                <td class="text-center font-weight-black">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="popViewDetails(item)"
                        fab
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <Icon
                          icon="material-symbols:display-settings-rounded"
                          color="#624fc6"
                          width="32"
                        />
                      </v-btn>
                    </template>
                    <span>عرض</span>
                  </v-tooltip>
                </td>
  
                <td class="text-center font-weight-black">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="popDelete(item.id)"
                        fab
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <Icon
                          icon="ic:round-delete-forever"
                          color="#C62828"
                          width="32"
                        />
                      </v-btn>
                    </template>
                    <span>حذف كورس</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="popEdit(item)"
                        fab
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <Icon
                          icon="material-symbols:edit-document-rounded"
                          color="#37474F"
                          width="32"
                        />
                      </v-btn>
                    </template>
                    <span>تعديل معلومات الكورس</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center py-5">
            <v-row>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-pagination
                  v-model="pagination.page"
                  :length="pageCount"
                  circle
                  color="#624fc6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-select
                  v-model="pagination.itemsPerPage"
                  :items="items"
                  color="#624fc6"
                  outlined
                  rounded
                  single-line
                  hide-details
                  reverse
                  label="عدد العناصر"
                  class="d-flex justify-center px-2"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </div>
          <PopViewDetails
            :value="pop_view"
            v-on:popClose="popClose()"
            :image="data_view"
          />
  
          <PopDelete
              :value="pop_delete"
              v-on:popClose="pop_delete = !pop_delete"
              v-on:deleteBanners="deleteBanners()" />
            <PopEdit
              :value="pop_edit"
              :banners="data_editor"
              v-on:popClose="pop_edit = !pop_edit" />
        </v-col>
      </v-row>
    </v-card>
  </template>
  <script>
  import PopDelete from "@/components/banners/PopDelete.vue";
  import PopEdit from "@/components/banners/PopEdit.vue";
  import PopViewDetails from "@/components/courses/PopViewDetails";
  export default {
  
      components: {PopDelete,PopEdit, PopViewDetails },
    data() {
      return {
        pop_view: false,
        data_view: null,
        pop_delete: false,
        data_deleted: null,
        pop_edit: false,
        data_editor: null,
        items: [5, 10, 15, 20, 25],
        pagination: {},
        headers: [
          {
            text: "رابط الاعلان",
            value: "title",
            class: "secondary white--text title",
            align: "center",
            width: "190px",
          },
          {
            text: "عرض صورة",
            value: "image",
            class: "secondary white--text title",
            align: "center",
            width: "190px",
          },
  
          {
            text: "العمليات",
            class: "secondary white--text title",
            align: "center",
            width: "130px",
          },
        ],
      };
    },
    computed: {
      pageCount() {
        return this.$store.state.banners.pageCount;
      },
  
      banners() {
        return this.$store.state.banners.banners;
      },
      loading() {
        return this.$store.state.banners.table_banners_loading;
      },
      params: {
        set(val) {
          this.$store.state.banners.params_banners = val;
        },
        get() {
          return this.$store.state.banners.params_banners;
        },
      },
    },
    methods: {
      // جلب المستخدمين
      get_banners() {
        let pagination = this.pagination;
        let par = {
          ...pagination,
        };
        this.params = par;
        this.$store.dispatch("banners/get_banners");
      },
      popDelete(item) {
        this.data_deleted = item;
        this.pop_delete = true;
      },
      popViewDetails(item) {
        this.data_view = item.image;
        this.pop_view = true;
      },
      popClose() {
        this.pop_view = false;
        this.data_view = null;
      },
      popEdit(item) {
        this.data_editor = item;
        this.pop_edit = true;
      },
      deleteBanners() {
          let data = {};
          data["id"] = this.data_deleted;
          this.$store.dispatch("banners/delete_banners", data).then(() => {
            this.pop_delete = false;
            this.data_deleted = null;
          });
        },
      reset_table() {
        this.$store.dispatch("banners/reset_table");
        this.get_banners();
      },
    },
    watch: {
      pagination: {
        handler() {
          this.get_banners();
        },
        deep: true,
      },
    },
  };
  </script>
  