import axios from "axios";
const banners = {
  namespaced: true,
  state: () => ({
    banners: [],
    table_banners_loading: false,
    add_banners_loading: false,
    pop_delete_loading: false,
    pop_edit_loading: false,

    pageCount: 1,

    params_banners: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
  }),
  mutations: {
    ADD_BANNERS(state, banners) {
      state.banners.unshift(banners);
    },

    EDIT_BANNERS(state, banners) {
      let index = state.banners.findIndex((e) => e.id === banners.id);
      state.banners.splice(index, 1);
      state.banners.unshift(banners);
    },

    DELETE_BANNERS(state, banners) {
      let index = state.banners.findIndex((e) => e.id == banners.id);
      state.banners.splice(index, 1);
    },

    GET_BANNERS(state, banne) {
      state.banners.splice(0, state.banners.length);
      banne.forEach((element) => {
        state.banners.push(element);
      });
    },
  },
  actions: {
    async reset_table({ state }) {
      state.pageCount = 1;
      state.banners = [];
      state.table_banners_loading = false;
      state.params_banners = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_banners({ commit, state, rootState }) {
      state.table_banners_loading = true;
      let data = state.params_banners;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        axios({
          url:
            `${rootState.server}` +
            "/api/get_banners" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_BANNERS", response.data.result);
            state.table_banners_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.table_banners_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_banners({ commit, state, rootState }, data) {
      state.table_banners_loading = true;
      state.add_banners_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/add_banners",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_BANNERS", response.data.result[0]);
            state.add_banners_loading = false;
            state.table_banners_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_banners_loading = false;
            state.table_banners_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    edit_banners({ commit, state, rootState }, data) {
      state.pop_edit_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/edit_banners",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "put",
        })
          .then((response) => {
            state.pop_edit_loading = false;
            commit("EDIT_BANNERS", response.data.result[0]);
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((err) => {
            state.pop_edit_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = err.response.data.message;
            this.commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              this.commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    delete_banners({ commit, state, rootState }, data) {
      state.pop_delete_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/delete_banners",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_BANNERS", data);
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default banners;
