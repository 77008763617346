<template>
  <v-row v-if="loading == false" class="mt-7 d-flex justify-start">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="(course, index) in courses"
      :key="index"
      class="d-flex justify-center"
    >
      <v-card
        class="card pb-6"
        :to="{
          name: 'courses',
          params: { slug: course.title },
          query: { id: course.id },
        }"
      >
        <v-card-title class="pb-1 d-flex justify-center">
          <v-img
            lazy-src="@/assets/40-10x6.jpg"
            class="image"
            v-if="course.image != null"
            :src="server + course.image"
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="#ffffff"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div
            v-if="
              course.offer != null &&
              moment().format('YYYY-MM-DD') <= course.offer_expired
            "
            class="ribbon2 ribbon-top-left"
          >
            <span>{{ course.offer }}%</span>
          </div>
        </v-card-title>
        <v-card-title class="pt-0 d-flex justify-start"
          ><h5>{{ course.title }}</h5>
        </v-card-title>
        <v-card-text>
          <h4 class="font-weight-black">
            {{ course.description | str_limit(115) }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-avatar size="50" color="#455A64">
            <v-img
              v-if="course.user.image != null"
              :src="server + course.user.image"
            >
            </v-img>

            <v-icon v-else size="30" color="white">mdi-account</v-icon>
          </v-avatar>
          <h4 class="mr-2">{{ course.user.name }}</h4>
          <v-spacer></v-spacer>
          <h4 v-if="course.course_type == 0">مجاني</h4>

          <h4
            v-else-if="
              course.course_type == 1 &&
              course.offer != null &&
              moment().format('YYYY-MM-DD') <= course.offer_expired
            "
          >
            {{
              (course.price - (course.price / 100) * course.offer)
                | formatNumber
            }}
          </h4>
          <h4 v-else>
            {{ course.price | formatNumber }}
          </h4>

          <font-awesome
            :icon="['fas', 'sack-dollar']"
            color="#388E3C"
            size="xl"
            class="mr-2"
          />
        </v-card-actions>

        <h4 class="d-flex justify-end ml-2 mt-n2">
          {{ course.time_course }} {{ course.time_type }}

          <font-awesome
            :icon="['fas', 'clock']"
            color="#FFA000"
            size="xl"
            class="mr-2"
          />
        </h4>
      </v-card>
    </v-col>
    <EmptyBox
      :loading="loading"
      :variable_array="courses"
      Text="لايوجد دورات متاحه"
    />
  </v-row>
</template>
<script>
import EmptyBox from "@/components/layout/EmptyBox";
export default {
  components: { EmptyBox },
  props: {
    loading: {
      typeof: Boolean,
      default: false,
    },
    courses: {
      typeof: Array,
      require: true,
    },
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
  },
};
</script>
<style scoped>
.card {
  width: 320px;
  cursor: pointer;
  border-radius: 15px !important;

  box-shadow: -4px 10px 50px 9px rgba(0, 0, 0, 0.25) !important;
}
.mbF {
  margin-bottom: 200px;
}
.image {
  width: 100%;
  max-width: 300px !important;
  max-height: 150px !important;
  border-radius: 15px 15px 15px 15px !important;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.image:hover {
  transform: scale(0.9);
  transition: all 0.3s ease 0s;
  box-shadow: #ffffff 0px 15px 30px;
}

/*  ribbon*/
.ribbon2 {
  width: 170px;
  height: 124px;
  overflow: hidden;
  position: absolute;
}
.ribbon2::before,
.ribbon2::after {
  position: absolute;
  content: "";
  display: block;
  border: 5px solid #624fc6;
}
.ribbon2 span {
  position: absolute;
  display: block;
  width: 226px;
  padding: 15px 0;
  background-color: #624fc6;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 19px/1 "Cairo", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-top-left {
  top: -12px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 2px;
  right: 45px;
}
.ribbon-top-left::after {
  bottom: -1px;
  left: 1px;
}
.ribbon-top-left span {
  right: 4px;
  top: 15px;
  transform: rotate(-45deg);
}
.offer {
  text-decoration: line-through;
  text-decoration-color: #624fc6;
  text-decoration-thickness: 3px;
}
.offer_price {
  margin-top: 0;
  margin-left: 6px;
}
</style>
