import axios from "axios";
const lessons = {
  namespaced: true,
  state: () => ({
    lessons: [],
    lessons_list: [],
    category: [],
    playerOptions: null,
    category_id: "",
    pageCount: 1,
    pageCount2: 1,

    params: {
      dropdown: true,
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    lessons_state: "done",

    params_category_dashboard: {
      dropdown: true,
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    category_state: "done",

    info_course: null,
    info_teacher: null,
    loading: false,

    ///  lessons_dashboard ///
    lessons_dashboard: [],
    lessons_query: "",
    pageCount3: 1,
    params_lessons_dashboard: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    pop_delete_loading: false,
    pop_edit_loading: false,
    add_lessons_loading: false,
    table_lessons_loading: false,
    //////// end /////////
  }),
  mutations: {
    GET_LESSONS(state, lesson) {
      state.lessons.splice(0, state.lessons.length);
      lesson.forEach((element) => {
        state.lessons.push(element);
      });

      // اذا كان يوجد دروس انشاء متغير لفيديو و استدعاءه في مشغل الفيدوهات
      if (lesson.length > 0) {
        state.info_course = lesson[0].course;
        state.info_teacher = lesson[0].course.user;
        // اذا كان نوع الرفع فيديو على منصة الموقع
        if (lesson[0].upload_type == 1) {
          var playerOptions = {};
          playerOptions["muted"] = false;
          playerOptions["language"] = "en";
          playerOptions["playbackRates"] = [0.7, 1.0, 1.5, 2.0];
          if (lesson[0].image) { //https://efredgvrergv34345435.online/api
            playerOptions["poster"] = 'https://efredgvrergv34345435.online/api' + lesson[0].image;
          }

          playerOptions["sources"] = [
            {
              type: "video/mp4",
              src: 'https://efredgvrergv34345435.online/api' + lesson[0].video,
            },
          ];
          state.playerOptions = playerOptions;
        }
        state.lessons_list.push(lesson[0]);
      }
    },

    CATEGORY_DREPDWON_SUCCESS(state, category) {
      if (category.length == 0) {
        state.category_state = "finished";
        if (state.params_category_dashboard.page > 1)
          state.params_category_dashboard.page =
            state.params_category_dashboard.page - 1;
        return;
      }
      state.params_category_dashboard.page =
        state.params_category_dashboard.page + 1;
      category.forEach((element) => {
        state.category.push(element);
      });
      state.category_state = "done";
    },

    GET_CATEGORY_DASHBOAED(state, category) {
      state.category.splice(0, state.category.length);
      category.forEach((element) => {
        state.category.push(element);
      });
      state.category_state = "done";
    },

    /////// lessons dashboard  //////

    GET_LESSONS_DASHBOARD(state, lessons) {
      state.lessons_dashboard.splice(0, state.lessons_dashboard.length);
      lessons.forEach((element) => {
        state.lessons_dashboard.push(element);
      });
    },
    ADD_LESSONS(state, lessons) {
      state.lessons_dashboard.unshift(lessons);
    },
    EDIT_LESSONS(state, lessons) {
      let index = state.lessons_dashboard.findIndex((e) => e.id === lessons.id);
      state.lessons_dashboard.splice(index, 1);
      state.lessons_dashboard.unshift(lessons);
    },

    DELETE_LESSONS(state, lessons) {
      let index = state.lessons_dashboard.findIndex((e) => e.id == lessons.id);
      state.lessons_dashboard.splice(index, 1);
    },
    ///////// end ////////
  },
  actions: {
    get_lessons({ commit, state, rootState }) {
      state.loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let category_id = "";
        if (
          state.category_id != undefined &&
          state.category_id != null &&
          state.category_id.length > 0
        )
          category_id = `&category_id=${state.category_id}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_lessons" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            category_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            state.loading = false;
            commit("GET_LESSONS", response.data.result);

            // if (data.dropdown == false) {
            //   commit("GET_LESSONS", response.data.result);
            // } else {
            //   commit("GET_LESSONS_DREPDWON_SUCCESS", response.data.result);
            // }


            resolve(response);
          })
          .catch(() => {
            state.loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_courses_category_lessons({ commit, state, rootState }) {
      let data = state.params_category_dashboard;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses_category_lessons" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount2 = response.data.count;
            if (data.dropdown == false) {
              commit("GET_CATEGORY_DASHBOAED", response.data.result);
            } else {
              commit("CATEGORY_DREPDWON_SUCCESS", response.data.result);
            }
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    ////////////////////// lessons dashboard ////////////////////////
    async reset_table({ state }) {
      state.pageCount3 = 1;
      state.lessons_dashboard = [];
      state.table_lessons_loading = false;
      state.params_lessons_dashboard = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },
    get_lessons_dashboard({ commit, state, rootState }) {
      state.table_lessons_loading = true;
      let data = state.params_lessons_dashboard;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let query = "";
        if (
          state.lessons_query != undefined &&
          state.lessons_query != null &&
          state.lessons_query.length > 0
        )
          query = `&query=${state.lessons_query}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_lessons_dashboard" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount3 = response.data.count;
            console.log("after.pageCount3",state.pageCount3)
            state.table_lessons_loading = false;
            commit("GET_LESSONS_DASHBOARD", response.data.result);

            resolve(response);
          })
          .catch(() => {
            state.table_lessons_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_lessons({ commit, state, rootState }, data) {
      state.table_lessons_loading = true;
      state.add_lessons_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/add_lessons",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_LESSONS", response.data.result[0]);
            state.table_lessons_loading = false;
            state.add_lessons_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.table_lessons_loading = false;
            state.add_lessons_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    edit_banners({ commit, state, rootState }, data) {
      state.pop_edit_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/edit_banners",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "put",
        })
          .then((response) => {
            state.pop_edit_loading = false;
            commit("EDIT_BANNERS", response.data.result[0]);
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((err) => {
            state.pop_edit_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = err.response.data.message;
            this.commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              this.commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    delete_lessons({ commit, state, rootState }, data) {
      state.pop_delete_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/delete_lessons",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_LESSONS", data);
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_vedio_lessons({ commit, rootState }, data) {
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/delete_vedio_lessons",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    /////////// end /////////
  },
};
export default lessons;
