import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import banners from "./modules/banners";
import courses from "./modules/courses";
import enrollments from "./modules/enrollments";
import lessons from "./modules/lessons";
import statistics from "./modules/statistics";
import courses_category from "./modules/courses_category";
import purchase_code from "./modules/purchase_code";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { //https://efredgvrergv34345435.online/api
    server: "https://efredgvrergv34345435.online/api",
    snackbar: false,
    snack_message: {},
    user_id: localStorage.getItem("user_id"),
    token: localStorage.getItem("token"),
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    user_type: localStorage.getItem("user_type"),
    loading_login: false,
    forgot_password_loading: false,
    model: localStorage.getItem("model") ?? 2,
    drawer: localStorage.getItem("drawer") ?? true,
  },

  mutations: {
    SNACK_MESSAGE(state, snack_message) {
      state.snack_message = snack_message;
      state.snackbar = true;
    },
    TIME_OUT(state) {
      state.snackbar = false;
      state.snack_message = null;
    },
    LOGIN_USER(state, data) {
      state.name = data.result[0].name;
      state.user_type = data.result[0].user_type;
    },
    CLEAR_USER() {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("user_type");
      localStorage.removeItem("model");
      localStorage.removeItem("drawer");
      localStorage.removeItem("admin");

      delete axios.defaults.headers.common["Authorization"];
      location.reload();
    },
  },
  actions: {
    login({ commit, state }, data) {
      state.loading_login = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/login",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((response) => {
            const data = response.data;
            // اذاكان المستخدم لم يقم بتاكيد البريد الالكتروني توجهيه الى صفحة تاكيد
            if (response.data.result[0].account_status == 0) {
              localStorage.setItem("confirm", 0);
              localStorage.setItem("email", data.result[0].email);
              localStorage.setItem("user_id", data.result[0].id);
              state.loading_login = false;

              let snack_message = {};
              snack_message["color"] = "#F57C00";
              snack_message["icon"] = "mdi:email-alert";
              snack_message["text"] = response.data.message;
              commit("SNACK_MESSAGE", snack_message);
              setTimeout(() => {
                commit("TIME_OUT", snack_message);
              }, 4000);

              router.push({ path: "/confirm_email" });
            }
            // اذا كان المستخدم قام بتاكيد بريده الالكتروني توجيهي الى موقع
            else {
              const token = response.data.token;
              commit("LOGIN_USER", data);
              state.loading_login = false;
              Vue.prototype.$http.defaults.headers.common["Authorization"] =
                "Bearer " + token;
              localStorage.setItem("token", token);
              localStorage.setItem("name", data.result[0].name);
              localStorage.setItem("user_id", data.result[0].id);
              localStorage.setItem("user_type", data.result[0].user_type);
              if (data.result[0].user_type == 0) {
                localStorage.setItem("admin", 0);
              } else if (data.result[0].user_type == 1) {
                localStorage.setItem("admin", 1);
              }
              localStorage.setItem("model", 2);
              localStorage.setItem("drawer", true);
              sessionStorage.setItem("pointer", 0);
              router.push({ path: "/" });
            }
            resolve(response);
          })
          .catch((error) => {
            state.loading_login = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    register({ commit, state }, data) {
      state.loading_login = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/register",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((response) => {
            const data = response.data;
            state.loading_login = false;
            localStorage.setItem("confirm", 0);
            localStorage.setItem("email", data.result[0].email);
            localStorage.setItem("user_id", data.result[0].id);
            resolve(response);
          })
          .catch(() => {
            state.loading_login = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "ادخلت اسم مستخدم او كلمة مرور غير صحيحة";
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    sendCodeAgain({ commit, state }, data) {
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/send_code_again",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    get_user({ commit, state }) {
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/get_user",
          method: "get",
        })
          .then((response) => {
            commit("GET_USER", response.data.result[0]);

            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    verifyAuthentication({ commit, state }, data) {
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/code_verification",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
            localStorage.removeItem("confirm");
            localStorage.removeItem("email");
            localStorage.removeItem("user_id");
            resolve(response);
          })
          .catch((error) => {
            let snack_message = {};
            snack_message["color"] = "#F57C00";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);

         
          });
      });
    },

    send_code_forgot_password({ commit, state }, data) {
      state.forgot_password_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/send_code_forgot_password",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.forgot_password_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);

            resolve(response);
          })
          .catch((error) => {
            state.forgot_password_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    check_code_forgot_password({ commit, state }, data) {
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/check_code_forgot_password",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            localStorage.removeItem("email");
            localStorage.setItem("id_reset_password", response.data.result[0]);
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);

            resolve(response);
          })
          .catch((error) => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    reset_password({ commit, state }, data) {
      state.forgot_password_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/reset_password",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.forgot_password_loading = false;
            localStorage.removeItem("id_reset_password");
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);

            resolve(response);
          })
          .catch((error) => {
            state.forgot_password_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    logout({ commit, state }) {
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/logout",
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CLEAR_USER");
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
  },
  getters: {
    snack_bar(state) {
      return !!state.snackbar;
    },
    isAdmin(state) {
      return state.admin;
    },
  },
  modules: {
    users,
    banners,
    courses,
    lessons,
    enrollments,
    statistics,
    courses_category,
    purchase_code,
  },
});
