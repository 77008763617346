<template>
  <v-card class="mx-auto card-table" width="100%">
    <v-row class="d-flex justify-center mb-9">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="pagination"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading || false"
          hide-default-footer
          loading-text="جاري التحميل يرجى الأنتظار"
          height="600"
          :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat class="mt-2">
              <v-toolbar-title>جدول المستخدمين</v-toolbar-title>

              <v-divider class="mx-4" inset vertical></v-divider>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="reset_table"
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <Icon
                      icon="solar:smartphone-update-bold"
                      color="#624fc6"
                      width="30"
                    />
                  </v-btn>
                </template>
                <span>تحديث معلومات الجدول</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="$vuetify.breakpoint.smAndUp"
                v-model="users_query"
                @input="query_change"
                append-icon="mdi-magnify"
                label="بحث"
                class="font-weight-black"
                :class="$vuetify.breakpoint.smOnly ? 'mb-2' : ''"
                color="#624fc6"
                outlined
                rounded
                clearable
                hide-details
              />
            </v-toolbar>
            <v-text-field
              v-if="$vuetify.breakpoint.xsOnly"
              v-model="users_query"
              @input="query_change"
              append-icon="mdi-magnify"
              label="بحث"
              class="font-weight-black mt-1 mb-2 px-2"
              color="#624fc6"
              outlined
              rounded
              clearable
              hide-details
            />
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td
                class="text-center font-weight-black"
                v-if="item.name == null"
              >
              <h4 style="color: red">لايوجد</h4>
              </td>
              <td
                class="text-center font-weight-black"
                v-else
              >
              {{ item.name }}
              </td>
              <td
                class="text-center font-weight-black"
                v-if="item.email == null"
              >
              <h4 style="color: red">لايوجد</h4>
              </td>
              <td
                class="text-center font-weight-black"
                v-else
              >
              {{ item.email }}
              </td>
              <td
                class="text-center font-weight-black"
                v-if="item.login_code == null"
              >
              <h4 style="color: red">لايوجد</h4>
              </td>
              <td
                class="text-center font-weight-black"
                v-else
              >
              {{ item.login_code }}
              </td>

              <td
                class="text-center font-weight-black"
                v-if="item.user_type == 0"
              >
                <h4 style="color: #ad519c">مدير نظام</h4>
              </td>
              <td
                class="text-center font-weight-black"
                v-else-if="item.user_type == 1"
              >
                <h4 style="color: #ffc94a">مدرس</h4>
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: #624fc6">مستخدم</h4>
              </td>
              <td
                class="text-center font-weight-black"
                v-if="item.account_status == 1"
              >
                <Icon
                  v-if="item.user_type == 0"
                  icon="mdi:user-network"
                  color="#388E3C"
                  width="32"
                />
                <Icon
                  v-else-if="item.user_type == 1"
                  icon="mdi:user-star"
                  color="#388E3C"
                  width="32"
                />
                <Icon v-else icon="mdi:user-check" color="#388E3C" width="32" />
              </td>
              <td
                class="text-center font-weight-black"
                v-else-if="item.account_status == 2"
              >
                <Icon icon="mdi:user-block" color="#C62828" width="32" />
              </td>
              <td class="text-center font-weight-black" v-else>
                <Icon icon="mdi:user-clock" color="#FFA000" width="32" />
              </td>

              <td class="text-center font-weight-black">
                <v-tooltip
                  bottom
                  v-if="item.account_status == 1 && item.user_type != 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="popBlocking(item.id)"
                    >
                      <Icon icon="mdi:user-lock" color="#C62828" width="32" />
                    </v-btn>
                  </template>
                  <span> حظر</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                  v-else-if="item.account_status == 2 && item.user_type != 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="popBlockingOpened(item.id)"
                    >
                      <Icon
                        icon="mdi:user-lock-open"
                        color="#388E3C"
                        width="32"
                      />
                    </v-btn>
                  </template>
                  <span> فتح الحظر</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                  v-else-if="item.account_status == 0 && item.user_type != 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="popActivation(item.id)"
                    >
                      <Icon icon="mdi:user-check" color="#388E3C" width="32" />
                    </v-btn>
                  </template>
                  <span> تفعيل</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-if="
                    item.account_status == 1 &&
                    item.user_type != 0 &&
                    item.user_type != 1
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="popUserUpgrade(item.id)"
                    >
                      <Icon icon="mdi:user-star" color="#624fc6" width="32" />
                    </v-btn>
                  </template>
                  <span> ترقية</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-if="
                    item.account_status == 1 &&
                    item.user_type != 0 &&
                    item.user_type == 1
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="popCancelUserUpgrade(item.id)"
                    >
                      <Icon icon="mdi:user-minus" color="#EF6C00" width="32" />
                    </v-btn>
                  </template>
                  <span> الغاء ترقية</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center py-5">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                circle
                color="#624fc6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                v-model="pagination.itemsPerPage"
                :items="items"
                color="#624fc6"
                outlined
                rounded
                single-line
                hide-details
                reverse
                label="عدد العناصر"
                class="d-flex justify-center px-2"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
        <ConfirmBlockingOpened
          :value="blocking_opened"
          v-on:popClose="blocking_opened = !blocking_opened"
          v-on:ConfirmBlockingOpened="open_block()"
        />
        <ConfirmBlocking
          :value="blocking"
          v-on:popClose="blocking = !blocking"
          v-on:ConfirmBlocking="block()"
        />
        <ConfirmActivation
          :value="activation"
          v-on:popClose="activation = !activation"
          v-on:ConfirmActivation="active()"
        />
        <UserUpgrade
          :value="user_upgrade"
          v-on:popClose="user_upgrade = !user_upgrade"
          v-on:UserUpgrade="upgrade()"
        />
        <CancelUpgrade
          :value="cancel_upgrade"
          v-on:popClose="cancel_upgrade = !cancel_upgrade"
          v-on:CancelUpgrade="cancel_user_upgrade()"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ConfirmBlocking from "@/components/user/ConfirmBlocking.vue";
import ConfirmBlockingOpened from "@/components/user/ConfirmBlockingOpened.vue";
import ConfirmActivation from "@/components/user/ConfirmActivation.vue";
import UserUpgrade from "@/components/user/UserUpgrade.vue";
import CancelUpgrade from "@/components/user/CancelUpgrade.vue";
export default {
  components: {
    ConfirmBlocking,
    ConfirmBlockingOpened,
    ConfirmActivation,
    UserUpgrade,
    CancelUpgrade,
  },
  data() {
    return {
      blocking: false,
      blocking_opened: false,
      activation: false,
      user_upgrade: false,
      cancel_upgrade: false,
      data_blocking: null,
      data_blocking_opened: null,
      data_activation: null,
      data_user_upgrade: null,
      data_cancel_upgrade: null,
      items: [5, 10, 15, 20, 25],
      pagination: {},
      headers: [
        {
          text: "الاسم",
          value: "user_name",
          class: "secondary white--text title",
          align: "center",
          width: "120px",
        },
        {
          text: "البريد الالكتروني",
          value: "email",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "رمز تسجيل الدخول",
          value: "email",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "نوع الحساب",
          value: "user_type",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "حالة الحساب",
          value: "account_status",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },

        {
          text: "العمليات",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
      ],
      adverstments: [],
    };
  },
  computed: {
    users_query: {
      set(val) {
        this.$store.state.users.users_query = val;
      },
      get() {
        return this.$store.state.users.users_query;
      },
    },
    pageCount() {
      return this.$store.state.users.pageCount;
    },

    users() {
      return this.$store.state.users.users;
    },
    loading() {
      return this.$store.state.users.table_loading;
    },
    params: {
      set(val) {
        this.$store.state.users.params = val;
      },
      get() {
        return this.$store.state.users.params;
      },
    },
  },
  methods: {
    // جلب المستخدمين
    get_users() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("users/get_users");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_users();
        this.pagination.page = 1;
      }, 1000);
    },

    active() {
      let data = {};
      data["id"] = this.data_activation;
      this.$store.dispatch("users/open_user", data).then(() => {
        this.activation = false;
        this.data_activation = null;
      });
    },
    block() {
      let data = {};
      data["id"] = this.data_blocking;
      this.$store.dispatch("users/block_user", data).then(() => {
        this.blocking = false;
        this.data_blocking = null;
      });
    },
    open_block() {
      let data = {};
      data["id"] = this.data_blocking_opened;
      this.$store.dispatch("users/open_user", data).then(() => {
        this.blocking_opened = false;
        this.data_blocking_opened = null;
      });
    },
    upgrade() {
      let data = {};
      data["id"] = this.data_user_upgrade;
      this.$store.dispatch("users/user_upgrade", data).then(() => {
        this.user_upgrade = false;
        this.data_user_upgrade = null;
      });
    },
    cancel_user_upgrade() {
      let data = {};
      data["id"] = this.data_cancel_upgrade;
      this.$store.dispatch("users/cancel_user_upgrade", data).then(() => {
        this.cancel_upgrade = false;
        this.data_cancel_upgrade = null;
      });
    },
    reset_table() {
      this.$store.dispatch("users/reset_table");
      this.get_users();
    },
    popBlocking(id) {
      this.data_blocking = id;
      this.blocking = true;
    },
    popBlockingOpened(id) {
      this.data_blocking_opened = id;
      this.blocking_opened = true;
    },
    popActivation(id) {
      this.data_activation = id;
      this.activation = true;
    },
    popUserUpgrade(id) {
      this.data_user_upgrade = id;
      this.user_upgrade = true;
    },
    popCancelUserUpgrade(id) {
      this.data_cancel_upgrade = id;
      this.cancel_upgrade = true;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_users();
      },
      deep: true,
    },
  },
};
</script>
<style>
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
</style>
