<template>
  <v-container class="register" fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-form ref="register">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="12" md="5" lg="5" class="justify-center order-lg-2 order-md-2 order-sm-2 order-2">
                <v-card-title class="d-flex justify-center mt-6 mb-3">
                  <h2>تسجيل حساب جديد</h2>
                </v-card-title>
                <v-card-text class="mt-2">
                  <Input
                    @update-value="name = $event"
                    :value="name"
                    type="text"
                    label="اسم المستخدم"
                    :rules="NameRules"
                  />
               
                  <Input
                    @update-value="email = $event"
                    :value="email"
                    type="text"
                    class="pt-5"
                    label="البريد الالكتروني"
                    :rules="EmailRules"
                  />

                  <v-text-field
                    color="#624fc6"
                    v-model="password"
                    outlined
                    rounded
                    clearable
                    class="font-weight-black pt-5"
                    :type="showPassword ? 'text' : 'password'"
                    label="كلمة المرور"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="PasswordsRules"
                  />
                  <v-text-field
                    color="#624fc6"
                    v-model="confirm_password"
                    outlined
                    rounded
                    clearable
                    class="font-weight-black "
                    :type="showPassword ? 'text' : 'password'"
                    label="تاكيد كلمة المرور"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="ConfirmPasswordRules"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="register"
                    :loading="loading_register"
                    color="#624fc6"
                    large
                    rounded
                    class="px-16 mx-auto"
                    elevation="4"
                  >
                    <h4 style="color: white; font-size: 17px">انشاء حساب</h4>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="7"
                lg="7"
                class="d-flex justify-center pa-0 ma-0 order-lg-1 order-md-1 order-sm-1 order-1"
              >
                <v-img class="img" src="../../assets/register.jpg" height="500">
                </v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "../../components/layout/Input.vue";
export default {
  components: { Input },
  data: () => ({
    name: null,
    email: null,
    password: null,
    confirm_password: null,
    showPassword: false,
    NameRules: [
      (value) => !!value || "هذا الحقل مطلوب",
      (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
    ],
    EmailRules: [
      (value) => !!value || "هذا الحقل مطلوب",
      (value) => (value || "").length >= 7 || "الحد الادنى لعدد الاحرف هوه 7",
      value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'ادخلت رموز غير صحيحه'
    },
    ],
  }),
  computed: {
    loading_register() {
      return this.$store.state.loading_login;
    },
    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
      ];
    },
    ConfirmPasswordRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
        (value) => value === (this.password || "") || "كلمة المرور غير متطابقه",
      ];
    },
  },
  methods: {
    register() {
      if (this.$refs.register.validate()) {
        let data = {};
        data["name"] = this.name;
        data["email"] = this.email;
        data["password"] = this.password;
        this.$store.dispatch("register", data).then(() => {
          this.$router.push({ path: "/confirm_email" });
        });
      }
    },
  },
};
</script>
<style scoped>
.card {
  margin-top: 60px !important;
  margin: auto;
  border-radius: 15px;
  font-family: "Cairo", sans-serif;
  box-shadow: 0px 0px !important;
}
.register {
  /* opacity: 80%; */
  height: 100%;
}
.img {
  border-radius: 15px;
}
.card-img {
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  /* border: 0.1px solid rgb(26, 95, 122); */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 100px;
}
.link-h4 {
  cursor: pointer;
}
.link-h4:hover {
  text-decoration: underline;
  color: darkblue;
}
</style>
