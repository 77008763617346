<template>
  <v-container class="courses" fluid>
    <v-row
      class="mt-14 flex-row-reverse"
      style="margin-top: 30px"
      v-if="loading == true"
    >
      <!-- skeleton -->
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="skeleton order-lg-2 order-md-2 order-sm-2 order-2"
      >
        <v-card class="card-list" max-width="550">
          <v-list
            two-line
            style="height: 670px; max-height: 670px"
            class="overflow-y-auto"
          >
            <v-list-item v-for="(n, index) in 9" :key="'A' + index" class="">
              <v-skeleton-loader
                width="330"
                type="list-item-two-line"
              ></v-skeleton-loader>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <!-- end -->
      <!-- skeleton -->
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
        class="order-lg-1 order-md-1 order-sm-1 order-1"
      >
        <v-card class="card-list pb-6">
          <v-list
            two-line
            style="height: 670px; max-height: 670px"
            class="overflow-y-auto"
          >
            <v-list-item class="d-flex justify-center">
              <v-skeleton-loader width="1200" type="image"></v-skeleton-loader>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <v-skeleton-loader width="1200" type="image"></v-skeleton-loader>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <!-- end -->
      <!-- skeleton -->
      <!-- <v-col cols="12" sm="6" md="4" lg="4">
        <v-card class="card-list pb-6" max-width="550">
          <v-list>
            <v-list-item class="d-flex justify-center">
              <v-skeleton-loader
                width="330"
                type="list-item-two-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col> -->
    </v-row>
    <!-- end -->
    <v-row
      class="mt-14 flex-row-reverse"
      v-if="loading == false && lessons.length > 0"
    >
      <!-- list lessons -->
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        class="order-lg-2 order-md-2 order-sm-2 order-2"
      >
        <v-card class="card-list" :width="width">
          <v-list
            v-scroll.self="handleScroll"
            two-line
            style="height: 670px; max-height: 670px"
            class="overflow-y-auto"
            v-if="lessons != null"
          >
            <v-list-item-group mandatory v-model="pointer" color="#624fc6">
              <v-subheader v-if="info_course != null">
                <h3>{{ info_course.title }}</h3></v-subheader
              >
              <v-list-item
                v-for="(lesson, index) in lessons"
                :key="index"
                @click="update_list(index, lesson)"
                :class="pointer == index ? 'list-item' : ''"
                class="py-2"
              >
                <v-list-item-avatar>
                  <h2>{{ index + 1 + "." }}</h2>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><h2>{{ lesson.title }}</h2></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <font-awesome
                    v-if="lessons_enrollment == 0 && index != 0"
                    :icon="['fas', 'lock']"
                    color="#455A64"
                    size="xl"
                    class="mr-2"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <!-- end -->

      <!-- video lessons -->
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
        class="order-lg-1 order-md-1 order-sm-1 order-1"
        v-for="(lesson, index) in lessons_list"
        :key="index"
      >
        <v-card class="card-list pb-6">
          <v-card-title class="pb-1 d-flex justify-center">
            <youtube
              class="video-js"
              :video-id="lesson.video_url"
              :player-vars="playerVars"
              v-if="lesson.upload_type == 0"
            ></youtube>

            <video-player
              v-else-if="lesson.upload_type == 1"
              class="vjs-custom-skin vjs_video_3-dimensions"
              ref="videoPlayer"
              :options="playerOptions"
              :poster="server + lesson.image"
              :playsinline="true"
              customEventName="customstatechangedeventname"
            >
            </video-player>
          </v-card-title>

          <v-card-title class="pt-0 d-flex justify-start"
            ><h2 class="mt-1">{{ lesson.title }}</h2>
          </v-card-title>
          <v-card-text>
            <h3>{{ lesson.content }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- end -->

      <!-- shere lessons and Teacher -->
      <!-- <v-col cols="12" sm="6" md="4" lg="4" v-if="info_teacher != null">
        <v-card class="card-list pb-6" max-width="550">
          <v-card-title
            class="shere-lesson pb-1 d-flex justify-center py-5"
            @click="PopShereLesson()"
          >
            <h4 style="color: #455a64" class="ml-2">مشاركة الدرس</h4>
            <v-icon color="#455A64" size="35">mdi-share-variant-outline</v-icon>
          </v-card-title>
          <v-divider class="mt-5" color="#CFD8DC"></v-divider>
          <v-card-title class="pb-1 d-flex justify-stert py-5">
            <v-avatar size="50" color="#455A64">
              <v-img
                v-if="info_teacher.image != null"
                :src="server + info_teacher.image"
              >
              </v-img>
              <v-icon v-else size="30" color="white">mdi-account</v-icon>
            </v-avatar>
            <h4 class="mr-2">{{ info_teacher.name }}</h4>
          </v-card-title>
        </v-card>
      </v-col> -->
      <!-- end -->
      <!-- <PopShereLesson
        :value="pop_shere_lessons"
        v-on:popClose="pop_shere_lessons = !pop_shere_lessons"
        :shere="data_shere"
      /> -->
      <PopEnrollment
        :value="pop_enrollment"
        v-on:popClose="pop_enrollment = !pop_enrollment"
      />
    </v-row>
    <v-row class="mt-7" v-if="loading == false && lessons.length == 0">
      <EmptyBox
        :loading="loading"
        :variable_array="lessons"
        Text="لايوجد دروس متاحه"
      />
    </v-row>
  </v-container>
</template>
<script>
// import PopShereLesson from "../components/courses/PopShereLesson.vue";
import PopEnrollment from "../components/courses/PopEnrollment.vue";
import EmptyBox from "../components/layout/EmptyBox.vue";
export default {
  components: {
    PopEnrollment,
    EmptyBox,
  },
  data() {
    return {
      playerVars: {
        autoplay: 0,
      },
      pointer: 0,
      data_shere: null,
      pop_shere_lessons: false,
      pop_enrollment: false,
    };
  },

  created() {
    var lessons = this.$store.state.lessons;

    lessons.lessons_list = [];
    lessons.lessons = [];
    lessons.info_course = null;
    lessons.category_id = this.$route.query.id;
    let data = {};
    data["category_id"] = this.$route.query.id;
    this.$store.dispatch("enrollments/check_enrollments", data);
    this.$store.dispatch("lessons/get_lessons");
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    loading() {
      return this.$store.state.lessons.loading;
    },
    playerOptions() {
      return this.$store.state.lessons.playerOptions;
    },
    lessons() {
      return this.$store.state.lessons.lessons;
    },
    info_course() {
      return this.$store.state.lessons.info_course;
    },
    info_teacher() {
      return this.$store.state.lessons.info_teacher;
    },
    lessons_enrollment() {
      return this.$store.state.enrollments.lessons_enrollment;
    },
    lessons_list() {
      return this.$store.state.lessons.lessons_list;
    },
    sessionStorage() {
      return sessionStorage.getItem("pointer");
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 550;
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return 600;
        case "xl":
          return 800;
        default:
          return 400; // Default value if none of the cases match
      }
    },
  },
  

  methods: {
    handleScroll(event) {
      const listElement = event.target;

      if (listElement.scrollHeight - listElement.scrollTop === listElement.clientHeight) {
        // Get the current date when scrolled to the bottom
        // const currentDate = new Date();
        console.log('Scrolled to the bottom on:');
        
        // You can perform any action you need with the date here
      }
    },

    update_list(index, lesson) {
      if (this.lessons_enrollment == 1) {
        var lessons_list = this.$store.state.lessons.lessons_list;
        lessons_list.splice(0, lessons_list.length);
        lessons_list.push(lesson);
        // اذا تم عرض فيديو على منصة الموقع
        if (lesson.upload_type == 1) {
          // معلومات لعرض الفيديو
          var playerOptions = {};
          playerOptions["muted"] = false;
          playerOptions["language"] = "en";
          playerOptions["playbackRates"] = [0.7, 1.0, 1.5, 2.0];
          if (lesson.image) {
            playerOptions["poster"] = this.server + lesson.image;
          }

          playerOptions["sources"] = [
            { type: "video/mp4", src: this.server + lesson.video },
          ];
          this.$store.state.lessons.playerOptions = playerOptions;
        } else {
          this.$store.state.lessons.playerOptions = null;
        }
        this.pointer = index;
      } else {
        this.pop_enrollment = true;
      }
    },
    PopShereLesson() {
      this.data_shere = this.$store.state.lessons.lessons_list;
      this.pop_shere_lessons = true;
    },
  },
  watch: {},
};
</script>
<style>
.reverse-row {
  display: flex;
  flex-direction: row-reverse;
}
.shere-lesson {
  cursor: pointer;
}

.courses {
  margin-top: 15px;
  margin-bottom: 60px;
}
.list-item {
  background: #fff3e0 !important;
}
.card-list {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
.vjs_video_3-dimensions {
  width: 100% !important;
  height: 550px !important;
}

.video-js {
  margin: auto;
  width: 100% !important;
  height: 550px !important;
}
/*  */
/*  */
.vjs-custom-skin > .video-js {
  width: 100%;
  font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}

.vjs-custom-skin > .video-js .vjs-menu-button-inline.vjs-slider-active,
.vjs-custom-skin > .video-js .vjs-menu-button-inline:focus,
.vjs-custom-skin > .video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em;
}

.vjs-custom-skin > .video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important;
}

.vjs-custom-skin > .video-js .vjs-control {
  width: 3em;
}

.vjs-custom-skin > .video-js .vjs-control.vjs-live-control {
  width: auto;
  padding-left: 0.5em;
  letter-spacing: 0.1em;
}

.vjs-custom-skin > .video-js .vjs-menu-button-inline:before {
  width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em;
}

.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button {
  display: block;
}

.vjs-custom-skin > .video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important;
}

.vjs-custom-skin > .video-js .vjs-mouse-display:after,
.vjs-custom-skin > .video-js .vjs-play-progress:after {
  padding: 0 0.4em 0.3em;
}

.video-js.vjs-ended .vjs-loading-spinner {
  display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important;
}

.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button {
  display: block;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  /*border-radius: 50%;*/
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}

.video-js:hover .vjs-big-play-button,
.vjs-custom-skin > .video-js .vjs-big-play-button:focus,
.vjs-custom-skin > .video-js .vjs-big-play-button:active {
  background-color: rgba(98, 79, 198, 1);
}

.vjs-custom-skin > .video-js .vjs-loading-spinner {
  border-color: rgba(36, 131, 213, 0.8);
}

.vjs-custom-skin > .video-js .vjs-control-bar2 {
  background-color: #000000;
}

.vjs-custom-skin > .video-js .vjs-control-bar {
  /*background-color: rgba(0,0,0,0.3) !important;*/
  color: #ffffff;
  font-size: 14px;
}

.vjs-custom-skin > .video-js .vjs-play-progress,
.vjs-custom-skin > .video-js .vjs-volume-level {
  background-color: #624fc6;
}

.vjs-custom-skin > .video-js .vjs-play-progress:before {
  top: -0.3em;
}

.vjs-custom-skin > .video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.3em;
}

.vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
  left: 0em;
}

.vjs-custom-skin > .video-js .vjs-menu li {
  padding: 0;
  line-height: 2em;
  font-size: 1.1em;
  font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}

.vjs-custom-skin > .video-js .vjs-time-tooltip,
.vjs-custom-skin > .video-js .vjs-mouse-display:after,
.vjs-custom-skin > .video-js .vjs-play-progress:after {
  border-radius: 0;
  font-size: 1em;
  padding: 0;
  width: 3em;
  height: 1.5em;
  line-height: 1.5em;
  top: -3em;
}

.vjs-custom-skin > .video-js .vjs-menu-button-popup .vjs-menu {
  width: 5em;
  left: -1em;
}

.vjs-custom-skin
  > .video-js
  .vjs-menu-button-popup.vjs-volume-menu-button-vertical
  .vjs-menu {
  left: 0;
}

/*排序顺序*/
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-play-control {
  order: 0;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control {
  min-width: 1em;
  padding: 0;
  margin: 0 0.1em;
  text-align: center;
  display: block;
  order: 1;
}

.vjs-custom-skin
  > .video-js
  .vjs-control-bar
  .vjs-playback-rate
  .vjs-playback-rate-value {
  font-size: 1.2em;
  line-height: 2.4;
}

.vjs-custom-skin > .video-js .vjs-progress-control.vjs-control {
  order: 2;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-volume-menu-button {
  order: 3;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-resolution-button {
  order: 4;
}

.vjs-custom-skin
  > .video-js
  .vjs-control-bar
  .vjs-resolution-button
  .vjs-resolution-button-label {
  display: block;
  line-height: 3em;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate {
  order: 5;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-fullscreen-control {
  order: 6;
}
</style>
