<template>
  <v-row v-if="loading == true" class="d-flex justify-center">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="(n, index) in 18"
      :key="'A' + index"
      class="skeleton"
    >
      <v-skeleton-loader
      class="ma-auto"
        max-width="310"
        type="card,list-item-three-line"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    loading: {
      typeof: Boolean,
      default: false,
    },
  },
};
</script>
