<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card">
          <v-toolbar dark color="#624fc6">
            <v-toolbar-title align-center>اضافة فئة كورس</v-toolbar-title>
          </v-toolbar>
          <v-form ref="add_courses_category">
            <v-card-text class="mt-7">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Input
                    @update-value="title = $event"
                    :value="title"
                    type="text"
                    label="الاسم"
                    :rules="RulseAll"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Selects
                    @update-value="course_type = $event"
                    :value="course_type"
                    itemValue="value"
                    itemText="type"
                    label="نوع فئة الكورس"
                    :items="course_ty"
                    :rules="RulseAll"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  v-if="course_type != null && course_type.value == 1"
                >
                  <Input
                    @update-value="price = $event"
                    :value="price"
                    type="number"
                    label="السعر"
                    :rules="RulseAll"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  v-if="course_type != null && course_type.value == 1"
                >
                  <Input
                    @update-value="offer = $event"
                    :value="offer"
                    type="number"
                    label="قيمة الخصم"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  v-if="course_type != null && course_type.value == 1"
                >
                  <InputDate
                    @update-value="offer_expired = $event"
                    :value="offer_expired"
                    color="#624fc6"
                    label="تاريخ انتهاء الخصم"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Input
                    @update-value="time_course = $event"
                    :value="time_course"
                    type="number"
                    label="مدة الكورس"
                    :rules="RulseAll"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Selects
                    @update-value="time_type = $event"
                    :value="time_type"
                    itemValue="value"
                    itemText="type"
                    label="نوع الوقت"
                    :items="time_ty"
                    :rules="RulseAll"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <Textarea
                    @update-value="description = $event"
                    :value="description"
                    type="text"
                    label="الوصف"
                    :rules="descriptionRules"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-autocomplete
                    ref="courseCategoryDropdown"
                    :items="course_dashboard"
                    v-model="course_id"
                    color="#624fc6"
                    hide-details="auto"
                    item-text="title"
                    item-value="id"
                    label="الكورسات"
                    :menu-props="menu_props"
                    outlined
                    rounded
                    clearable
                    lazy-rules
                    :rules="RulseAll"
                    class="font-weight-black"
                  >
                    <template v-slot:append-item>
                      <div
                        v-intersect="userInteract"
                        v-if="
                          !(
                            $store.state.courses_category.course_state ==
                            'finished'
                          )
                        "
                        class="text-lg-center"
                      ></div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <div
                    class="d-flex justify-start mr-2"
                    style="display: flex; justify-content: center"
                  >
                    <VueUploadMultipleImage
                      idUpload="image-upload-courses-category"
                      idEdit="image-edit-courses-category"
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      :multiple="false"
                      dragText="اضف صور"
                      primaryText="تم الاضافة بنجاح"
                      browseText=""
                      maxImageSize="1"
                    />
                  </div>
                  <h3 class="text-start mt-2 mr-2">اضف صوره فئة كورس :</h3>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mt-2 pb-5">
              <v-btn
                @click="add_courses_category"
                :loading="loading"
                color="#624fc6"
                large
                rounded
                class="px-16 mx-auto"
                elevation="4"
              >
                <h4 style="color: white; font-size: 17px">اضافة</h4>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <Table />
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Table from "@/components/courses_category/Table.vue";
import Selects from "@/components/layout/Selects.vue";
import Textarea from "@/components/layout/Textarea.vue";
import InputDate from "@/components/layout/InputDate.vue";
export default {
  components: { Input, Table, Selects, Textarea, InputDate },
 
  data() {
    return {
      title: null,
      price: null,
      time_course: null,
      course_type: null,
      time_type: null,
      description: null,
      course_id: null,
      offer: null,
      offer_expired: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      course_ty: [
        { type: "مدفوع", value: true },
        { type: " مجاني", value: false },
      ],
      time_ty: [
        { type: "ساعه", value: true },
        { type: " دقيقه", value: false },
      ],
      images: [],
      upload: [],

      RulseAll: [(v) => !!v || "هذا الحقل مطلوب"],
      descriptionRules: [
        (v) =>
          (v || "").length <= 510 ||
          "لقد وصلت الحد الاقصى لعدد الحروف المطلوبة",
      ],

      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch("courses_category/get_courses_dashboard");
  },

  computed: {
    loading() {
      return this.$store.state.courses_category.add_courses_category_loading;
    },
    course_dashboard() {
      return this.$store.state.courses_category.course_dashboard;
    },
  },
  methods: {
    add_courses_category() {
      if (this.$refs.add_courses_category.validate()) {
        let data = {};
        data["title"] = this.title;
        data["description"] = this.description;
        data["course_type"] = this.course_type.value;
        data["time_course"] = this.time_course;
        data["time_type"] = this.time_type.type;
        data["course_id"] = this.course_id;

        data["image"] = this.upload[0];
        if (this.course_type.value == 1) {
          data["price"] = this.price;
        }
        if (this.offer != null && this.offer != 0) {
          data["offer_expired"] = this.offer_expired;
          data["offer"] = this.offer;
        }

        this.$store
          .dispatch("courses_category/add_courses_category", data)
          .then(() => {
            this.$refs.add_courses_category.reset();
            this.images = [];
            this.upload = [];
          });
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    beforeRemove(index, done) {
      var r = confirm("سوف يتم حذف الصورة");
      if (r == true) {
        done();
        this.upload.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    get_course_categories() {
      if (this.$store.state.courses_category.course_state == "finished") return;
      this.$store.dispatch("courses_category/get_courses_dashboard");
    },
    userInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.get_course_categories(); // onscroll
          this.$refs.courseCategoryDropdown.onScroll();
        }, 500);
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
</style>
