<template>
  <v-card class="mx-auto mt-8 card-table" width="100%">
    <v-row class="d-flex justify-center mb-9">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="enrollments"
          :options.sync="pagination"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading || false"
          hide-default-footer
          loading-text="جاري التحميل يرجى الأنتظار"
          height="600"
          :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat class="mt-2">
              <v-toolbar-title>جدول الاشتراكات</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="reset_table"
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <Icon
                      icon="solar:smartphone-update-bold"
                      color="#624fc6"
                      width="30"
                    />
                  </v-btn>
                </template>
                <span>تحديث معلومات الجدول</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="$vuetify.breakpoint.smAndUp"
                v-model="enrollments_query"
                @input="query_change"
                append-icon="mdi-magnify"
                label="بحث"
                :class="$vuetify.breakpoint.smOnly ? 'mb-2' : ''"
                class="font-weight-black"
                color="#624fc6"
                outlined
                rounded
                clearable
                hide-details
              />
            </v-toolbar>
            <v-text-field
              v-if="$vuetify.breakpoint.xsOnly"
              v-model="enrollments_query"
              @input="query_change"
              append-icon="mdi-magnify"
              label="بحث"
              class="font-weight-black mt-1 mb-2 px-2"
              color="#624fc6"
              outlined
              rounded
              clearable
              hide-details
            />
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center font-weight-black">
                {{ item.order_id }}
              </td>

              <td class="text-center font-weight-black" v-if="item.category">
                {{ item.category.title }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>
              <td class="text-center font-weight-black" v-if="item.teacher">
                {{ item.teacher.name }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>

              <td class="text-center font-weight-black" v-if="item.user">
                {{ item.user.name }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>

              <td class="text-center font-weight-black">
                {{ item.price | formatNumber() }} د.ع
              </td>

              <td
                class="text-center font-weight-black"
                v-if="item.payment_type == 0"
              >
                نقد
              </td>
              <td
                class="text-center font-weight-black"
                v-if="item.payment_type == 1"
              >
                زين كاش
              </td>

              <td class="text-center font-weight-black">
                {{ moment(item.subscription_time).format("YYYY-MM-DD") }}
              </td>
              <td class="text-center font-weight-black">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      small
                      text
                      @click="show_invoice(item.invoice)"
                    >
                      <Icon
                        class="icon_download"
                        icon="ph:download-fill"
                        color="#624fc6"
                        width="30"
                      />
                    </v-btn>
                  </template>
                  <span>فاتورة</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center py-5">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                circle
                color="#624fc6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                v-model="pagination.itemsPerPage"
                :items="items"
                color="#624fc6"
                outlined
                rounded
                single-line
                hide-details
                reverse
                label="عدد العناصر"
                class="d-flex justify-center px-2"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      items: [5, 10, 15, 20, 25],
      pagination: {},
      headers: [
        {
          text: "رقم الطلب",
          value: "order_id",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "اسم فئة الكورس",
          value: "title",
          class: "secondary white--text title",
          align: "center",
          width: "200px",
        },
        {
          text: "اسم الاستاذ",
          value: "teacher_id",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "اسم المستخدم",
          value: "user_id",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },

        {
          text: "السعر",
          value: "price",
          class: "secondary white--text title",
          align: "center",
          width: "130px",
        },

        {
          text: "نوع الدفع",
          value: "payment_type",
          class: "secondary white--text title",
          align: "center",
          width: "180px",
        },

        {
          text: "تاريخ الاشتراك",
          value: "subscription_time",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "وصل الشراء",
          value: "subscription_time",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
      ],
    };
  },
  computed: {
    enrollments_query: {
      set(val) {
        this.$store.state.enrollments.enrollments_query = val;
      },
      get() {
        return this.$store.state.enrollments.enrollments_query;
      },
    },
    pageCount() {
      return this.$store.state.enrollments.pageCount;
    },

    enrollments() {
      return this.$store.state.enrollments.enrollments;
    },
    loading() {
      return this.$store.state.enrollments.table_enrollments_loading;
    },
    server() {
      return this.$store.state.server;
    },
    params: {
      set(val) {
        this.$store.state.enrollments.params = val;
      },
      get() {
        return this.$store.state.enrollments.params;
      },
    },
  },
  methods: {
    // جلب المستخدمين
    get_enrollments() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("enrollments/get_enrollments");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_enrollments();
        this.pagination.page = 1;
      }, 1000);
    },

    reset_table() {
      this.$store.dispatch("enrollments/reset_table");
      this.get_enrollments();
    },
    show_invoice(invoice) {
      window.open(this.server + invoice, "_blank");
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_enrollments();
      },
      deep: true,
    },
  },
};
</script>
