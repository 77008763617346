<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card">
          <v-toolbar dark color="#624fc6">
            <v-toolbar-title align-center>اضافة كود شراء</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form ref="add_purchase_code">
            <v-card-text class="mt-7">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <v-autocomplete
                    ref="PurchaseCodeDropdown"
                    :items="paid_courses"
                    v-model="category_id"
                    color="#624fc6"
                    hide-details="auto"
                    item-text="title"
                    item-value="id"
                    label="فئات الكورسات"
                    :menu-props="menu_props"
                    outlined
                    rounded
                    clearable
                    lazy-rules
                    :rules="RulseAll"
                    class="font-weight-black"
                  >
                    <template v-slot:append-item>
                      <div
                        v-intersect="userInteract"
                        v-if="
                          !($store.state.lessons.category_state == 'finished')
                        "
                        class="text-lg-center"
                      ></div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mt-2 pb-5">
              <v-btn
                @click="add_purchase_code"
                :loading="loading"
                color="#624fc6"
                large
                rounded
                class="px-16 mx-auto"
                elevation="4"
              >
                <h4 style="color: white; font-size: 17px">اضافة</h4>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <Table />
  </v-container>
</template>
<script>
import Table from "@/components/purchase_code/Table.vue";
export default {
  components: { Table },
 
  data() {
    return {
      category_id: null,
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],

      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
    };
  },

  mounted() {
    this.$store.dispatch("purchase_code/get_courses_category_dashboard");
  },
  computed: {
    paid_courses() {
      return this.$store.state.purchase_code.paid_courses;
    },
    loading() {
      return this.$store.state.purchase_code.add_code_loading;
    },
  },

  methods: {
    add_purchase_code() {
      if (this.$refs.add_purchase_code.validate()) {
        let data = {};
        data["category_id"] = this.category_id;

        this.$store
          .dispatch("purchase_code/add_purchase_code", data)
          .then(() => {
            this.$refs.add_purchase_code.reset();
          });
      }
    },

    get_paid_courses() {
      if (this.$store.state.purchase_code.paid_courses == "finished") return;
      this.$store.dispatch("purchase_code/get_courses_category_dashboard");
    },
    userInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.get_paid_courses(); // onscroll
          this.$refs.PurchaseCodeDropdown.onScroll();
        }, 500);
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
</style>
