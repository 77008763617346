import axios from "axios";
const courses_category = {
  namespaced: true,
  state: () => ({
    course_categories: [],
    course_dashboard: [],
    paid_courses: [],
    types_courses: [],
    pageCount0: 1,
    pageCount1: 1,
    pageCount2: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    params_courses_dashboard: {
      dropdown: true,
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    params_paid_courses: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

    paid_courses_category_query: "",
    courses_category_query: "",
    course_state: "done",

    filter_name_courses: null,
    filter_name_paid_courses: null,

    add_courses_category_loading: false,
    pop_delete_loading: false,
    pop_edit_loading: false,
    // table_courses_loading: false,
    add_category_loading: false,

    loading_category: false,
    loading_paid_courses: false,
  }),
  mutations: {
    ADD_CATEGORY(state, category) {
      state.course_categories.unshift(category);
    },
    EDIT_COURSES_CATEGORIES(state, courses) {
      let index = state.course_categories.findIndex((e) => e.id === courses.id);
      state.course_categories.splice(index, 1);
      state.course_categories.unshift(courses);
    },

    DELETE_COURSES_CATEGORIES(state, courses) {
      let index = state.course_categories.findIndex((e) => e.id == courses.id);
      state.course_categories.splice(index, 1);
    },

    GET_COURSES_CATEGORIES(state, course) {
      state.course_categories.splice(0, state.course_categories.length);
      course.forEach((element) => {
        state.course_categories.push(element);
      });
    },
    GET_PAID_COURSES(state, course) {
      state.paid_courses.splice(0, state.paid_courses.length);
      course.forEach((element) => {
        state.paid_courses.push(element);
      });
    },

    COURSES_DREPDWON_SUCCESS(state, course) {
      if (course.length == 0) {
        state.course_state = "finished";
        if (state.params_courses_dashboard.page > 1)
          state.params_courses_dashboard.page =
            state.params_courses_dashboard.page - 1;
        return;
      }
      state.params_courses_dashboard.page =
        state.params_courses_dashboard.page + 1;
      course.forEach((element) => {
        state.course_dashboard.push(element);
      });
      state.course_state = "done";
    },

    GET_COURSES_DASHBOAED(state, course) {
      state.course_dashboard.splice(0, state.course_dashboard.length);
      course.forEach((element) => {
        state.course_dashboard.push(element);
      });
      state.course_state = "done";
    },
  },
  actions: {
    async reset_table({ state }) {
      state.pageCount1 = 1;
      state.course_categories = [];
      state.loading_category = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },
    get_courses_dashboard({ commit, state, rootState }) {
      let data = state.params_courses_dashboard;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount0 = response.data.count;
            if (data.dropdown == false) {
              commit("GET_COURSES_DASHBOAED", response.data.result);
            } else {
              commit("COURSES_DREPDWON_SUCCESS", response.data.result);
            }
            resolve(response);
          })
          .catch(() => {
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_courses_category({ commit, state, rootState }) {
      state.loading_category = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        // بحث
        let query = "";
        if (
          state.courses_category_query != undefined &&
          state.courses_category_query != null &&
          state.courses_category_query.length > 0
        )
          query = `&query=${state.courses_category_query}`;

        // فلترة بين الدورات
        var filter = "";

        if (
          state.filter_name_courses != undefined &&
          state.filter_name_courses != null
        ) {
          filter = "&filter=" + JSON.stringify(state.filter_name_courses);
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses_category" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            filter +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount1 = response.data.count;
            commit("GET_COURSES_CATEGORIES", response.data.result);
            state.loading_category = false;
            resolve(response);
          })
          .catch(() => {
            state.loading_category = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_paid_courses({ commit, state, rootState }) {
      state.loading_paid_courses = true;
      let data = state.params_paid_courses;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        // بحث
        let query = "";
        if (
          state.paid_courses_category_query != undefined &&
          state.paid_courses_category_query != null &&
          state.paid_courses_category_query.length > 0
        )
          query = `&query=${state.paid_courses_category_query}`;
        // فلترة بين الدورات
        var filter = "";
        if (
          state.filter_name_paid_courses != undefined &&
          state.filter_name_paid_courses != null
        ) {
          filter = "&filter=" + JSON.stringify(state.filter_name_paid_courses);
        }

        // فلتر لاحضار الكورسات المفدوعه فقط
        var filter_paid_course = "";
        let filter_paid_courses = {};
        filter_paid_courses["name"] = "course_type";
        filter_paid_courses["value"] = 1;

        if (filter_paid_courses != undefined && filter_paid_courses != null) {
          filter_paid_course =
            "&filter_paid_course=" + JSON.stringify(filter_paid_courses);
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/get_courses_category" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            filter_paid_course +
            filter +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount2 = response.data.count;
            commit("GET_PAID_COURSES", response.data.result);
            state.loading_paid_courses = false;
            resolve(response);
          })
          .catch(() => {
            state.loading_paid_courses = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخدام";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_courses_category({ commit, state, rootState }, data) {
      state.loading_category = true;
      state.add_courses_category_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/add_course_category",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_CATEGORY", response.data.result[0]);
            state.loading_category = false;
            state.add_courses_category_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.loading_category = false;
            state.add_courses_category_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    edit_courses_category({ commit, state, rootState }, data) {
      state.pop_edit_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/edit_courses_category",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "put",
        })
          .then((response) => {
            state.pop_edit_loading = false;
            commit("EDIT_COURSES_CATEGORIES", response.data.result[0]);
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((err) => {
            state.pop_edit_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = err.response.data.message;
            this.commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              this.commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    delete_courses_category({ commit, state, rootState }, data) {
      state.pop_delete_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/delete_courses_category",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_COURSES_CATEGORIES", data);
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.pop_delete_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default courses_category;
