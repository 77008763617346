<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card">
          <v-toolbar dark color="#624fc6">
            <v-toolbar-title align-center>اضافة دروس</v-toolbar-title>
          </v-toolbar>
          <v-form ref="add_lessons">
            <v-card-text class="mt-7">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Input
                    @update-value="title = $event"
                    :value="title"
                    type="text"
                    label="عنوان الدرس"
                    :rules="RulseAll"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <Textarea
                    @update-value="content = $event"
                    :value="content"
                    type="text"
                    label="الوصف"
                    :rules="descriptionRules"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <Selects
                    @update-value="upload_type = $event"
                    :value="upload_type"
                    itemValue="value"
                    itemText="type"
                    label="نوع رفع الفيديو"
                    :items="upload_ty"
                    :rules="RulseAll"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-autocomplete
                    ref="courseCategoryDropdown"
                    :items="category"
                    v-model="category_id"
                    color="#624fc6"
                    hide-details="auto"
                    item-text="title"
                    item-value="id"
                    label="فئات الكورسات"
                    :menu-props="menu_props"
                    outlined
                    rounded
                    clearable
                    lazy-rules
                    :rules="RulseAll"
                    class="font-weight-black"
                  >
                    <template v-slot:append-item>
                      <div
                        v-intersect="userInteract"
                        v-if="
                          !($store.state.lessons.category_state == 'finished')
                        "
                        class="text-lg-center"
                      ></div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  v-if="upload_type != null && upload_type.value == 0"
                >
                  <Input
                    @update-value="video_url = $event"
                    :value="video_url"
                    type="text"
                    label="رابط الفيديو"
                    :rules="RulseAll"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <vue-dropzone
                    ref="myFileDropzone"
                    id="dropzone"
                    :options="dropzoneOptionsFileLeesons"
                    :includeStyling="true"
                    :useCustomSlot="true"
                    @vdropzone-removed-file="remove_file_lesson"
                    @vdropzone-success="uploadSuccessFile"
                    @vdropzone-error="fileUploadErrorFile"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title-file">
                        قم بالسحب والإفلات لتحميل ملف
                      </h3>
                      <div class="subtitle">
                        ...أو انقر لتحديد ملف من جهاز الكمبيوتر الخاص بك
                      </div>
                    </div>
                  </vue-dropzone>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="3"
                  v-if="upload_type != null && upload_type.value == 1"
                >
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    :includeStyling="true"
                    :useCustomSlot="true"
                    @vdropzone-removed-file="remove_file"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-error="fileUploadError"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        قم بالسحب والإفلات لتحميل فيديو الدرس
                      </h3>
                      <div class="subtitle">
                        ...أو انقر لتحديد ملف من جهاز الكمبيوتر الخاص بك
                      </div>
                    </div>
                  </vue-dropzone>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  v-if="upload_type != null && upload_type.value == 1"
                >
                  <div
                    class="d-flex justify-start mr-2"
                    style="display: flex; justify-content: center"
                  >
                    <VueUploadMultipleImage
                      idUpload="image-upload-courses-lessons"
                      idEdit="image-edit-courses-lessons"
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      :multiple="false"
                      dragText="اضف صور"
                      primaryText="تم الاضافة بنجاح"
                      browseText=""
                      maxImageSize="1"
                    />
                  </div>
                  <h3 class="text-start mt-2 mr-2">اضف صوره الدرس :</h3>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mt-2 pb-5">
              <v-btn
                @click="add_lessons"
                :loading="loading"
                color="#624fc6"
                large
                rounded
                class="px-16 mx-auto"
                elevation="4"
              >
                <h4 style="color: white; font-size: 17px">اضافة</h4>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <Table />
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Table from "@/components/lessons/Table.vue";
import Textarea from "@/components/layout/Textarea.vue";
import Selects from "@/components/layout/Selects.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: { Input, Textarea, vueDropzone: vue2Dropzone, Table, Selects },

  data() {
    return {
      title: null,
      content: null,
      video: null,
      file: null,
      category_id: null,
      upload_type: null,
      upload_ty: [
        { type: "المنصة", value: true },
        { type: " يوتويب", value: false },
      ],
      images: [],
      upload: [],

      dropzoneOptions: {
        //https://efredgvrergv34345435.online/api/api/upload_vedio_lessons
        url: "http://127.0.0.1:8000/api/upload_vedio_lessons/api/upload_vedio_lessons",
        method: "post",
        thumbnailWidth: 200,
        maxFilesize: 10,
        parallelUploads: 6,
        uploadMultiple: false,
        addRemoveLinks: true,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
        },
      },

      RulseAll: [(v) => !!v || "هذا الحقل مطلوب"],
      descriptionRules: [
        (v) =>
          (v || "").length <= 255 ||
          "لقد وصلت الحد الاقصى لعدد الحروف المطلوبة",
      ],

      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },

      // upload file
      dropzoneOptionsFileLeesons: {
        url: "http://127.0.0.1:8000/api/upload_vedio_lessons/api/upload_vedio_lessons",
        method: "post",
        thumbnailWidth: 200,
        maxFilesize: 1,
        parallelUploads: 1,
        uploadMultiple: false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf",
        maxFiles: 1,

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch("lessons/get_courses_category_lessons");
    this.remove_file();
  },

  computed: {
    loading() {
      return this.$store.state.lessons.add_lessons_loading;
    },
    category() {
      return this.$store.state.lessons.category;
    },
  },
  methods: {
    uploadSuccess(file, response) {
      localStorage.setItem("video", response.result[0]);
      let snack_message = {};
      snack_message["color"] = "#21ce8f";
      snack_message["icon"] = "clarity:success-standard-solid";
      snack_message["text"] = response.message;
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    fileUploadError(error, xhr) {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = xhr.message;
      this.$store.commit("SNACK_MESSAGE", snack_message, { root: true });
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message, { root: true });
      }, 4000);
    },

    remove_file() {
      if (localStorage.getItem("video")) {
        let data = {};
        data["vedio"] = localStorage.getItem("video");
        this.$store.dispatch("lessons/delete_vedio_lessons", data).then(() => {
          localStorage.removeItem("video");
        });
      }
    },
    add_lessons() {
      if (this.$refs.add_lessons.validate()) {
        let data = {};
        data["title"] = this.title;
        data["content"] = this.content;
        data["category_id"] = this.category_id;
        data["upload_type"] = this.upload_type.value;
        data["file"] = this.file;

        if (this.upload_type.value == 1) {
          if (this.upload.length > 0) {
            data["image"] = this.upload[0];
          }
          data["video"] = localStorage.getItem("video");
        } else {
          data["video_url"] = this.video_url;
        }

        this.$store.dispatch("lessons/add_lessons", data).then(() => {
          localStorage.removeItem("video");
          this.$refs.add_lessons.reset();
          this.images = [];
          this.upload = [];
          this.file = null;
        });
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    beforeRemove(index, done) {
      var r = confirm("سوف يتم حذف الصورة");
      if (r == true) {
        done();
        this.upload.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
    },
    get_courses_category_lessons() {
      if (this.$store.state.lessons.category_state == "finished") return;
      this.$store.dispatch("lessons/get_courses_category_lessons");
    },
    userInteract(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          this.get_courses_category_lessons(); // onscroll
          this.$refs.courseCategoryDropdown.onScroll();
        }, 500);
      }
    },

    // upload file
    uploadSuccessFile(file, response) {
      this.file = response["file"];
    },
    fileUploadErrorFile() {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = "لا يمكنك تحميل ملفات من هذا النوع.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    remove_file_lesson() {
      this.file = null;
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
</style>
<style>
.dz-remove {
  margin-right: 80px !important;
  margin-left: 0 !important;
}
.dropzone-custom-content {
  margin-top: 60px;
  text-align: center;
}

.dropzone-custom-title {
  font-family: "Cairo", sans-serif;
  margin-top: 0;
  color: #624fc6;
}
.dropzone-custom-title-file {
  font-family: "Cairo", sans-serif;
  margin-top: 0;
  color: #f57c00;
}

.subtitle {
  font-family: "Cairo", sans-serif;
  color: #314b5f;
}
.dropzone {
  height: 250px;
  border-radius: 10px;
}
.vue-dropzone:hover {
  background-color: #ffffff !important;
}
.dz-preview {
  height: 150px !important;
  display: block !important;
}
.dz-details {
  top: 0px !important;
}
.dz-error-message {
  top: 150px !important;
}
</style>
