<template>
  <div>
    <v-app-bar
      v-if="$vuetify.breakpoint.mdAndDown"
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      app
      clipped-right
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        class="d-flex justify-end toolbar-title"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'ps-10'"
        style="color: black"
        @click="link_home"
      >
        <!--  site name -->
        <v-img
          src="../../assets/play_home.png"
          class="ml-5"
          width="30"
          alt="play"
        ></v-img>
        <h4>تعليمي</h4>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      dir="ltr"
      nable-resize-watcher
      :clipped="clipped"
      v-model="drawer"
      app
      right
      class="navigation-drawer"
    >
      <v-list-item @click="link_home">
        <img src="@/assets/play.png" class="ml-6" width="35" alt="play" />

        <v-list-item-content>
          <v-list-item-title
            ><h2 class="text-center mt-4 mb-4" style="color: white">
              تعليمي
            </h2></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item-group mandatory :value="model" color="#1f2544">
          <v-list-item
            v-for="(opation, index) in filteredItems"
            :key="index"
            :to="opation.link"
            @click="models(opation.count)"
            :class="model == opation.count ? 'list-item' : ''"
            class="list-itemAll"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-end mr-2">
                <h3
                  :class="
                    model == index ? 'color-icon-title-D' : 'color-icon-title-A'
                  "
                >
                  {{ opation.title }}
                </h3>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="icons">
              <Icon
                :icon="opation.icon"
                :class="
                  model == index ? 'color-icon-title-D' : 'color-icon-title-A'
                "
                width="30"
              />
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="d-flex justify-center">
          <v-btn
            class="btn-logout"
            x-large
            color="white"
            @click="logout"
            outlined
          >
            <h4>تسجيل خروج</h4>
            <Icon icon="majesticons:logout-line" color="white" width="32" />
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  props: {
    model: {
      typeof: Number,
      required: true,
      default: 0,
    },
  },
  data: () => ({
    drawer: true,
    group: null,
    clipped: false,

    opations: [
      {
        count: 100,
        title: "الحصائيات",
        icon: "wpf:statistics",
        type: 0,
      },
      {
        count: 200,
        title: "المستخدمين",
        icon: "mdi:users-add",
        type: 0,
      },
      {
        count: 2,
        title: "الكورسات",
        icon: "material-symbols:play-lesson",
        type: 0,
        type2: 1,
      },
      {
        count: 3,
        title: "فئات الكورسات",
        icon: "material-symbols:list-alt",
        type: 0,
        type2: 1,
      },
      {
        count: 4,
        title: "الدروس",
        icon: "streamline:class-lesson-solid",
        type: 0,
        type2: 1,
      },
      {
        count: 5,
        title: "الاشتراكات",
        icon: "solar:chat-round-money-bold",
        type: 0,
        type2: 1,
      },
      {
        count: 6,
        title: "اكواد الشراء",
        icon: "icon-park-outline:source-code",
        type: 0,
        type2: 1,
      },
      {
        count: 300,
        title: "اعلانات",
        icon: "material-symbols:ads-click",
        type: 0,
      },

      // solar:wallet-money-bold solar:chat-round-money-bold
    ],
  }),

  computed: {
    user_type() {
      return this.$store.state.user_type;
    },
    filteredItems() {
      return this.opations.filter(
        (opation) =>
          opation.type == this.user_type || opation.type2 == this.user_type
      );
    },
  },
  methods: {
    models(count) {
      this.$emit("update-model", count);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    link_home() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.btn-logout {
  margin-bottom: 30px;
  border-radius: 15px !important;
}
.navigation-drawer {
  background: linear-gradient(180deg, #624fc6 0%, #ad519c 99%);
}
.v-list-item:before {
  border-radius: 15px !important;
  background: rgb(185, 237, 221) !important;
  opacity: 0;
}

.list-item {
  border-radius: 15px !important;
  background: #1f2544 !important;
}
.list-itemAll:hover {
  border-radius: 15px !important;
  background: #1f2544 !important;
}
.color-icon-title-A {
  color: rgb(255, 255, 255, 0.8);
}
.color-icon-title-D {
  color: rgb(255, 255, 255);
}
.type_zero {
  margin-top: 450px;
}
.type_one {
  margin-top: 29px;
}
.type_two {
  margin-top: 239px;
}
.type_three_four {
  margin-top: 99px;
}
</style>
