<template>
  <div>
    <v-card class="card" width="100%" :height="500">
      <v-card-title class="d-flex justify-center pb-0 mb-0"
        >احصائيات الطلاب المشتركين </v-card-title
      >

      <LineChartGenerator
        :width="400"
        :height="height"
        :chart-options="options"
        :chart-data="chart_data"
      />
    </v-card>
    
  </div>
</template>
<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
Title, Tooltip, Legend, ArcElement, CategoryScale;
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: { LineChartGenerator },
  data() {
    return {
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        stacked: false,
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 14,
                family: "'Cairo', sans-serif",
                weight: "bold",
              },
            },
          },
        },
      },
    };
  },
  computed:{
    chart_data(){
      return this.$store.state.statistics.chart_12_months
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 320;
        case "sm":
          return 190;
        case "md":
          return 200;
        case "lg":
          return 150;
        case "xl":
          return 150;
        default:
          return 150; // Default value if none of the cases match
      }
    },
  }
};
</script>
<style scoped>
 .card {
    border-radius: 25px !important;
    box-shadow: 0px 0px 0px 0px !important;
  }
  </style>