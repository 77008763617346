<template>
  <v-card class="mx-auto mt-8 card-table" width="100%">
    <v-row class="d-flex justify-center mb-9">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="purchase_code"
          :options.sync="pagination"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :loading="loading || false"
          hide-default-footer
          loading-text="جاري التحميل يرجى الأنتظار"
          height="600"
           :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat class="mt-2">
              <v-toolbar-title>جدول اكود الشراء</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="reset_table"
                    fab
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <Icon
                      icon="solar:smartphone-update-bold"
                      color="#624fc6"
                      width="30"
                    />
                  </v-btn>
                </template>
                <span>تحديث معلومات الجدول</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center font-weight-black" v-if="item.course">
                {{ item.course.title }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>
              <td class="text-center font-weight-black" v-if="item.teacher">
                {{ item.teacher.name }}
              </td>
              <td class="text-center font-weight-black" v-else>
                <h4 style="color: red">محذوف</h4>
              </td>

              <td class="text-center font-weight-black">
                {{ item.code }}
              </td>

              <td class="text-center font-weight-black">
                <Icon
                  v-if="item.status == 0"
                  icon="tabler:eye-code"
                  color="#388E3C"
                  width="32"
                />
                <Icon
                  v-else-if="item.status == 1"
                  icon="tabler:lock-code"
                  color="#C62828"
                  width="32"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center py-5">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                circle
                color="#624fc6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                v-model="pagination.itemsPerPage"
                :items="items"
                color="#624fc6"
                outlined
                rounded
                single-line
                class="d-flex justify-center px-2"
                hide-details
                reverse
                label="عدد العناصر"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
        <PopViewDetails
          :value="pop_view"
          v-on:popClose="popClose()"
          :image="data_view"
        />

        <PopDelete
          :value="pop_delete"
          v-on:popClose="pop_delete = !pop_delete"
          v-on:deleteBanners="deleteBanners()"
        />
        <PopEdit
          :value="pop_edit"
          :banners="data_editor"
          v-on:popClose="pop_edit = !pop_edit"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import PopDelete from "@/components/banners/PopDelete.vue";
import PopEdit from "@/components/banners/PopEdit.vue";
import PopViewDetails from "@/components/courses/PopViewDetails";
export default {
  components: { PopDelete, PopEdit, PopViewDetails },
  data() {
    return {
      pop_view: false,
      data_view: null,
      pop_delete: false,
      data_deleted: null,
      pop_edit: false,
      data_editor: null,
      items: [5, 10, 15, 20, 25],
      pagination: {},
      headers: [
        {
          text: "الكورس",
          value: "category_id",
          class: "secondary white--text title",
          align: "center",
          width: "130px",
        },
        {
          text: "اسم الاستاذ",
          value: "teacher_id",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "كود الشراء",
          value: "code",
          class: "secondary white--text title",
          align: "center",
          width: "190px",
        },
        {
          text: "الحالة",
          value: "status",
          class: "secondary white--text title",
          align: "center",
          width: "130px",
        },
      ],
    };
  },
  computed: {
    pageCount() {
      return this.$store.state.purchase_code.pageCount;
    },

    purchase_code() {
      return this.$store.state.purchase_code.purchase_code;
    },
    loading() {
      return this.$store.state.purchase_code.table_banners_loading;
    },
    params: {
      set(val) {
        this.$store.state.purchase_code.params = val;
      },
      get() {
        return this.$store.state.purchase_code.params;
      },
    },
  },
  methods: {
    // جلب المستخدمين
    get_purchase_code() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("purchase_code/get_purchase_code");
    },
    reset_table() {
      this.$store.dispatch("purchase_code/reset_table");
      this.get_purchase_code();
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_purchase_code();
      },
      deep: true,
    },
  },
};
</script>
