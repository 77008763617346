<template>
  <v-card flat tile class="text-center footer pt-16">
    <v-footer style="background-color: #e0e4e4;"  inset class="d-flex justify-center">
      <v-card-text class="black--text font-weight-black">
        جميع الحقوق محفوظة {{ new Date().getFullYear() }} &copy;
      </v-card-text>
    </v-footer>
  </v-card>
</template>
<script>
export default {
  data() {
    return {};
  },


  
};
</script>
<style scoped>
.footer {
  background-color: #e0e4e4 !important;
}
</style>
