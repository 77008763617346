<template>
  <v-container>
    <v-row>
      <v-dialog :value="value" persistent scrollable max-width="900px" dir="rtl">
        <v-card width="100%">
          <v-card-title v-if="image">
            <v-img
              :width="width"
              :height="height"
              class="mx-auto"
              :src="server + image"
            ></v-img>
          </v-card-title>
          <v-card-title v-else>
            <!-- skeleton -->

            <v-skeleton-loader width="100%" type="image"></v-skeleton-loader>

            <!-- end -->
          </v-card-title>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
              <h4>اغلاق</h4>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      require: true,
    },
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return 500;
        case "md":
          return 600;
        case "lg":
          return 600;
        case "xl":
          return 600;
        default:
          return "100%"; // Default value if none of the cases match
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return 500;
        case "xl":
          return 500;
        default:
          return "100%"; // Default value if none of the cases match
      }
    },
  },
  methods: {},
};
</script>
